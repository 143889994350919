import React from 'react';
import "./HelpCenter.css";
import HelpCenterCards from './HelpCenterCards';
import BlogSection from '../LandingPage/BlogSection';

const HelpCenterMain = () => {
  return (
    <>
    <HelpCenterCards/>
    <BlogSection />
    </>
  )
}

export default HelpCenterMain