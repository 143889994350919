import React from "react";
import BlogFirst from '../../assets/img/Blog01.png';
import BlogSecond from '../../assets/img/Blog-Hero-810x810.png';
import BlogThird from '../../assets/img/Blog-Productivity-Inline-2.png';

const BlogSection = () => {
  return (
    <>
      <section className="blog-section pt-3 p-0 mx-3">
        <div className="container">

            {/* Blog Heading starts here */}
          <div className="row my-3">
            <div className="infoCards-heading col-md-8 text-start">
              <p className="introCardsPara">— Latest articles from Mozayc</p>
              <h1 className="introCardsHead">
                We would like to help you to build perfect ecommerce system
              </h1>
            </div>
          </div>

          {/* Info Blog Cards */}
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="BlogCard p-4">
                    <div className="BlogImg m-0 p-0 position-relative">
                        <img className="h-100 w-100" src={BlogFirst} />
                        <span className="newBlogSpan py-1 px-2">NEW</span>
                    </div>
                    <div className="blogDetails">
                        <p className="blogDetails-para my-4 px-1">—     3. Aug 2022   ·   4 min. read</p>
                        <h3 className="blogDetails-heading px-1">Design tips for designers, that cover everything you need to increase your skill</h3>
                    </div>
                </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="BlogCard p-4">
                    <div className="BlogImg m-0 p-0">
                        <img className="h-100 w-100" src={BlogSecond} />
                    </div>
                    <div className="blogDetails">
                        <p className="blogDetails-para my-4 px-1">—     3. Aug 2022   ·   4 min. read</p>
                        <h3 className="blogDetails-heading px-1">Enhancing Customer Engagement and Sales: The Benefits of Live Chat Software</h3>
                    </div>
                </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="BlogCard p-4">
                    <div className="BlogImg m-0 p-0">
                        <img className="h-100 w-100" src={BlogThird} />
                    </div>
                    <div className="blogDetails">
                        <p className="blogDetails-para my-4 px-1">—     3. Aug 2022   ·   4 min. read</p>
                        <h3 className="blogDetails-heading px-1">Securing Your E-commerce API with Laravel Sanctum: A Complete Guide</h3>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogSection;
