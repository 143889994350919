import React from 'react';
import './Showcase.css';
import TemplatesShowcase from './TemplatesShowcase';
import BlogSection from '../LandingPage/BlogSection';

const ShocaseMain = () => {
  return (
    <>
    <TemplatesShowcase/>
    <BlogSection/>
    </>
  )
}

export default ShocaseMain