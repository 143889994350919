import React from "react";
import "../PricingMain.css";
import OurEarlyBidSecond from "./OurEarlyBidSecond.js";
import Faqs from "../Faqs.js";
import BlogSection from "../../LandingPage/BlogSection.js";
import References from "./References.js";

const PricingSecond = () => {
  return (
    <>
      <OurEarlyBidSecond />
      <References />
      <Faqs />
      <BlogSection />
    </>
  );
};

export default PricingSecond;
