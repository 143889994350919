import React from "react";
import Unlock from "../../../assets/img/UnlockCourse.png";
import Faqs from "../../Pricing/Faqs";
import BlogSection from "../../LandingPage/BlogSection";

const Courses = () => {
  return (
    <>
      <div className="container mt-md-5">
        <div className="row">
          <div className="col-12">
            <div className="pt-3 pt-md-5">
              <h3 className="faqs-head pb-4 mb-3 text-start">Online Courses</h3>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="courses-main p-2 w-100">
              <div className="courses-btn-main position-relative">
                <button className="btn btn-primary btn-course-start">
                  Start course
                </button>
              </div>
              <div className="courses-heading-main py-3">
                <h1 className="courses-head">SEO Training for Beginners</h1>
                <p className="courses-para">— 2 hours 3 mins · 32 lessons</p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4">
            <div className="courses-main p-2 w-100">
              <div className="courses-btn-main position-relative">
                <button className="btn btn-primary btn-course-start">
                  Start course
                </button>
              </div>
              <div className="courses-heading-main py-3">
                <h1 className="courses-head">
                  Grow Your Business with Instagram
                </h1>
                <p className="courses-para">— 2 hours 3 mins · 32 lessons</p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4">
            <div className="courses-main p-2 w-100">
              <div className="courses-btn-main position-relative">
                <button className="btn btn-primary btn-course-start">
                  Start course
                </button>
              </div>
              <div className="courses-heading-main py-3">
                <h1 className="courses-head">
                  Facebook Ads for Beginners: Retention and Loyalty
                </h1>
                <p className="courses-para">— 2 hours 3 mins · 32 lessons</p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4">
            <div className="courses-main p-2 w-100">
              <div className="courses-btn-main position-relative">
                <button className="btn btn-primary btn-course-start">
                  Start course
                </button>
              </div>
              <div className="courses-heading-main py-3">
                <h1 className="courses-head">Ecommerce Email Marketing 101</h1>
                <p className="courses-para">— 2 hours 3 mins · 32 lessons</p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4">
            <div className="courses-main p-2 w-100">
              <div className="courses-btn-main position-relative">
                <button className="btn btn-primary btn-course-start">
                  Start course
                </button>
              </div>
              <div className="courses-heading-main py-3">
                <h1 className="courses-head">
                  How to Develop and Manufacture Your Product
                </h1>
                <p className="courses-para">— 2 hours 3 mins · 32 lessons</p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4">
            <div className="courses-main p-2 w-100">
              <div className="courses-btn-main position-relative">
                <button className="btn btn-primary btn-course-unlock d-flex align-items-center">
                  <img className="me-2" src={Unlock} />
                  <span>Unlock</span>
                </button>
              </div>
              <div className="courses-heading-main py-3">
                <h1 className="courses-head">
                  Turn Your Hobby into a Business
                </h1>
                <p className="courses-para">— 2 hours 3 mins · 32 lessons</p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4">
            <div className="courses-main p-2 w-100">
              <div className="courses-btn-main position-relative">
                <button className="btn btn-primary btn-course-unlock d-flex align-items-center">
                  <img className="me-2" src={Unlock} />
                  <span>Unlock</span>
                </button>
              </div>
              <div className="courses-heading-main py-3">
                <h1 className="courses-head">SEO Training for Beginners</h1>
                <p className="courses-para">— 2 hours 3 mins · 32 lessons</p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4">
            <div className="courses-main p-2 w-100">
              <div className="courses-btn-main position-relative">
                <button className="btn btn-primary btn-course-unlock d-flex align-items-center">
                  <img className="me-2" src={Unlock} />
                  <span>Unlock</span>
                </button>
              </div>
              <div className="courses-heading-main py-3">
                <h1 className="courses-head">
                  Grow Your Business with Instagram
                </h1>
                <p className="courses-para">— 2 hours 3 mins · 32 lessons</p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4">
            <div className="courses-main p-2 w-100">
              <div className="courses-btn-main position-relative">
                <button className="btn btn-primary btn-course-unlock d-flex align-items-center">
                  <img className="me-2" src={Unlock} />
                  <span>Unlock</span>
                </button>
              </div>
              <div className="courses-heading-main py-3">
                <h1 className="courses-head">
                  Facebook Ads for Beginners: Retention and Loyalty
                </h1>
                <p className="courses-para">— 2 hours 3 mins · 32 lessons</p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4">
            <div className="courses-main p-2 w-100">
              <div className="courses-btn-main position-relative">
                <button className="btn btn-primary btn-course-unlock d-flex align-items-center">
                  <img className="me-2" src={Unlock} />
                  <span>Unlock</span>
                </button>
              </div>
              <div className="courses-heading-main py-3">
                <h1 className="courses-head">Ecommerce Email Marketing 101</h1>
                <p className="courses-para">— 2 hours 3 mins · 32 lessons</p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4">
            <div className="courses-main p-2 w-100">
              <div className="courses-btn-main position-relative">
                <button className="btn btn-primary btn-course-unlock d-flex align-items-center">
                  <img className="me-2" src={Unlock} />
                  <span>Unlock</span>
                </button>
              </div>
              <div className="courses-heading-main py-3">
                <h1 className="courses-head">
                  How to Develop and Manufacture Your Product
                </h1>
                <p className="courses-para">— 2 hours 3 mins · 32 lessons</p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4">
            <div className="courses-main p-2 w-100">
              <div className="courses-btn-main position-relative">
                <button className="btn btn-primary btn-course-unlock d-flex align-items-center">
                  <img className="me-2" src={Unlock} />
                  <span>Unlock</span>
                </button>
              </div>
              <div className="courses-heading-main py-3">
                <h1 className="courses-head">
                  Turn Your Hobby into a Business
                </h1>
                <p className="courses-para">— 2 hours 3 mins · 32 lessons</p>
              </div>
            </div>
          </div>
        </div>
        <div className="theme-list-button-main pt-4 d-flex justify-content-start mt-3 mb-5">
          <button className="theme-list-button me-2 active">1</button>
          <button className="theme-list-button me-2">2</button>
          <button className="theme-list-button me-2">3</button>
        </div>
      </div>
      <Faqs />
      <div className="mb-5 pb-5">
        <BlogSection />
      </div>
    </>
  );
};

export default Courses;
