import React from 'react';
import MoreInfo from "../../assets/img/more-info.svg";
import YearlyPrice from "../../assets/img/Combined Shape.svg";
import Magic from "../../assets/img/magicPrice.svg";
import Verify from "../../assets/img/verify.svg";
import Shape from "../../assets/img/Shape.svg";
import Lock from "../../assets/img/lock.svg";
export const Monthly = () => {
  return (
    <>
    <div className="row mt-5 gap-0">
    <div className="col-12 p-0 col-lg-3 mb-3 mb-lg-0">
      <div className="pricingInfoMain-pricing border-left-none border-top-none">
        <div className="height-price-head-max">
          <div className="pt-lg-5 pb-lg-4 mx-4">
            <h3 className="startup-heading">Our Plans</h3>
            {/* <p className="startup-para">Hit the ground running</p> */}
          </div>
          <div className="d-flex align-items-end pt-lg-4 pb-lg-2 mx-lg-4">
            {/* <h3 className="price-heading  mb-1">24 €</h3>
          <p className="price-para px-1 mb-2">/ monthly</p> */}
          </div>
          <div className="pb-4">
            {/* <div className="getStartbtnDiv d-flex align-items-center justify-content-center p-2 mx-4 btn btn-primary col-10">
            <span className="getStartbtnSpan">
              Get started — it's free
            </span>
          </div>
          <span className="getstart-span">
            No credit card required. Cancel anytime.
          </span> */}
          </div>
        </div>
        <div className="featuresMain hight-price-first-max pb-2 pb-lg-4 border-bottom-price">
          <ul className="features-ul mt-4 mb-lg-5">
            <div className="pt-lg-5 pb-lg-4 mx-4">
              <h3 className="pricing-subHeadings">
                Content management system
              </h3>
            </div>
          </ul>
        </div>

        <div className="featuresMain hight-price-second-max pb-2 pb-lg-4 border-bottom-price">
          <ul className="features-ul mt-4 mb-lg-5">
            <div className="pt-lg-5 pb-lg-4 mx-4">
              <h3 className="pricing-subHeadings">eCommerce</h3>
            </div>
          </ul>
        </div>

        <div className="featuresMain hight-price-third-max pb-2 pb-lg-4 border-bottom-price">
          <ul className="features-ul pt-4 mb-lg-5">
            <div className="pt-lg-5 pb-lg-4 mx-4">
              <h3 className="pricing-subHeadings">Marketing</h3>
            </div>
          </ul>
        </div>

        <div className="featuresMain hight-price-fourth-max pb-2 pb-lg-4 border-bottom-price">
          <ul className="features-ul mt-4 mb-lg-5">
            <div className="pt-lg-5 pb-lg-4 mx-4">
              <h3 className="pricing-subHeadings">Promotion</h3>
            </div>
          </ul>
        </div>

        <div className="featuresMain hight-price-fifth-max pb-2 pb-lg-4 border-bottom-price">
          <ul className="features-ul mt-4 mb-lg-5">
            <div className="pt-lg-5 pb-lg-4 mx-4">
              <h3 className="pricing-subHeadings">Analytics</h3>
            </div>
          </ul>
        </div>

        <div className="featuresMain hight-price-sixth-max pb-2 pb-lg-4 border-bottom-price">
          <ul className="features-ul mt-4 mb-lg-5">
            <div className="pt-lg-5 pb-lg-4 mx-4">
              <h3 className="pricing-subHeadings">Administration</h3>
            </div>
          </ul>
        </div>

        <div className="d-flex align-items-end pt-4 pb-2 mx-4">
          {/* <h3 className="price-heading  mb-1">24 €</h3> */}
          {/* <p className="price-para px-1 mb-2">/ monthly</p> */}
        </div>
        <div className="pb-4 ">
          {/* <div className="getStartbtnDiv d-flex align-items-center justify-content-center p-2 mx-4 btn btn-primary col-10">
            <span className="getStartbtnSpan">
              Get started — it's free
            </span>
          </div>
          <span className="getstart-span">
            No credit card required. Cancel anytime.
          </span> */}
        </div>
      </div>
    </div>

    <div className="col-12 p-0 col-lg-3 mb-3 mb-lg-0">
      <div className="pricingInfoMain-pricing border-left-none border-top-none">
        <div className="height-price-head-max">
          <div className="startup-main pt-5 pb-4 mx-0 border-bottom-price  mx-lg-4 px-4 px-lg-0">
            <h3 className="startup-heading">Startup</h3>
            <p className="startup-para">Hit the ground running</p>
          </div>
          <div className="d-flex align-items-end pt-4 pb-2 mx-4">
            <h3 className="price-heading  mb-1">24 €</h3>
            <p className="price-para px-1 mb-2">/ monthly</p>
          </div>
          <div className="pb-4">
            <div className="getStartbtnDiv d-flex align-items-center justify-content-center p-2 mx-4 btn btn-primary col-10">
              <span className="getStartbtnSpan">
                Get started — it's free
              </span>
            </div>
            <span className="getstart-span">
              No credit card required. Cancel anytime.
            </span>
          </div>
        </div>
        <div className="featuresMain hight-price-first-max pb-4 border-bottom-price">
          <ul className="features-ul mt-4 mb-5">
            <li className="features-list d-flex justify-content-between">
              Theme builder
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Design editor
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Site structure
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Pages
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Blogs/Articles
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Banners
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Galleries
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              SEO
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Multimedia
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Drive 10GB
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Languages
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Sitemap
              <img className="pe-3" src={MoreInfo} />
            </li>
          </ul>
        </div>

        <div className="featuresMain hight-price-second-max pb-4 border-bottom-price">
          <ul className="features-ul mt-4 mb-5">
            <li className="features-list d-flex justify-content-between">
              Products
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Catalogs
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Categories
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Statuses
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Orders
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Carts
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Invoices
              <img className="pe-3" src={MoreInfo} />
            </li>
          </ul>
        </div>

        <div className="featuresMain hight-price-third-max pb-4 border-bottom-price">
          <ul className="features-ul pt-4 mb-5">
            <li className="features-list d-flex justify-content-between">
              Emailing — 100 daily
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Loyalty program
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              SMS gateway — 50 daily
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              CRM
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Social networks
              <img className="pe-3" src={MoreInfo} />
            </li>
          </ul>
        </div>

        <div className="featuresMain hight-price-fourth-max pb-4 border-bottom-price">
          <ul className="features-ul mt-4 mb-5">
            <li className="features-list d-flex justify-content-between"></li>
            <li className="features-list d-flex justify-content-between"></li>
            <li className="features-list d-flex justify-content-between"></li>
          </ul>
        </div>

        <div className="featuresMain hight-price-fifth-max pb-4 border-bottom-price">
          <ul className="features-ul mt-4 mb-5">
            <li className="features-list d-flex justify-content-between">
              Customers
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Sales
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Orders
              <img className="pe-3" src={MoreInfo} />
            </li>
          </ul>
        </div>

        <div className="featuresMain hight-price-sixth-max pb-4 border-bottom-price">
          <ul className="features-ul mt-4 mb-5">
            <li className="features-list d-flex justify-content-between">
              Domain management
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              System languages
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Account setting
              <img className="pe-3" src={MoreInfo} />
            </li>
          </ul>
        </div>

        <div className="d-flex align-items-end pt-4 pb-2 mx-4">
          <h3 className="price-heading  mb-1">24 €</h3>
          <p className="price-para px-1 mb-2">/ monthly</p>
        </div>
        <div className="pb-4 border-bottom-none">
          <div className="getStartbtnDiv d-flex align-items-center justify-content-center p-2 mx-4 btn btn-primary col-10">
            <span className="getStartbtnSpan">
              Get started — it's free
            </span>
          </div>
          <span className="getstart-span">
            No credit card required. Cancel anytime.
          </span>
        </div>
      </div>
    </div>

    <div className="col-12 p-0 col-lg-3 my-3 my-lg-0">
      <div className="pricingInfoMain-pricing  border-left-none border-top-none">
        <div className="height-price-head-max position-relative">
          <div className="most-popular py-2">
            <span>
              <img className="pe-1" src={Magic} />
              Most popular
            </span>
          </div>
          <div className="startup-main pt-5 pb-4 mx-0 border-bottom-price  mx-lg-4 px-4 px-lg-0">
            <h3 className="startup-heading">Growing</h3>
            <p className="startup-para">Power Up your business</p>
          </div>
          <div className="d-flex align-items-end pt-4 pb-2 mx-4">
            <h3 className="price-heading  mb-1">96 €</h3>
            <p className="price-para px-1 mb-2">/ monthly</p>
          </div>
          <div className="pb-4">
            <div className="getStartbtnDiv d-flex align-items-center justify-content-center p-2 mx-4 btn btn-primary col-10">
              <span className="getStartbtnSpan">
                Get started — it's free
              </span>
            </div>
            <span className="getstart-span">
              No credit card required. Cancel anytime.
            </span>
          </div>
        </div>
        <div className="featuresMain hight-price-first-max pb-4 border-bottom-price">
          <ul className="features-ul mt-4 mb-5">
            <li className="features-list d-flex justify-content-between">
              Theme builder
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Design editor
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Site structure
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Pages
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Blogs/Articles
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Banners
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Galleries
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              SEO
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Multimedia
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Drive 50GB
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Languages
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Sitemap
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Picture editor
              <img className="pe-3" src={MoreInfo} />
            </li>
          </ul>
        </div>

        <div className="featuresMain hight-price-second-max pb-4 border-bottom-price">
          <ul className="features-ul mt-4 mb-5">
            <li className="features-list d-flex justify-content-between">
              Products
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Catalogs
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Categories
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Statuses
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Orders
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Carts
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Invoices
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Atributes
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Warehouses
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Logistic
              <img className="pe-3" src={MoreInfo} />
            </li>
          </ul>
        </div>

        <div className="featuresMain hight-price-third-max pb-4 border-bottom-price">
          <ul className="features-ul pt-4 mb-5">
            <li className="features-list d-flex justify-content-between">
              Emailing — 1000 daily
              <div className="on-marketing-hover position-relative">
                <img className="pe-3 cursor-pointer" src={MoreInfo} />
                <div className="px-4 py-3 marketing-hover">
                  <h4 className="marketing-hover-head">
                    Emailing — 1000 daily
                  </h4>
                  <p className="marketing-hover-para">
                    Full featured CMS with SEO and languages management
                    will take care that your clients will quickly find
                    you on the internet whatever language they speak.
                  </p>
                </div>
              </div>
            </li>
            <li className="features-list d-flex justify-content-between">
              Loyalty program
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              SMS gateway — 500 daily
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              CRM
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Social networks
              <img className="pe-3" src={MoreInfo} />
            </li>
          </ul>
        </div>

        <div className="featuresMain hight-price-fourth-max pb-4 border-bottom-price">
          <ul className="features-ul mt-4 mb-5">
            <li className="features-list d-flex justify-content-between">
              Promo codes
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Promo events
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Discounts
              <img className="pe-3" src={MoreInfo} />
            </li>
          </ul>
        </div>

        <div className="featuresMain hight-price-fifth-max pb-4 border-bottom-price">
          <ul className="features-ul mt-4 mb-5">
            <li className="features-list d-flex justify-content-between">
              Customers
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Sales
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Orders
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Traffic, acquisition, real-time, conversion
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Geo
              <img className="pe-3" src={MoreInfo} />
            </li>
          </ul>
        </div>

        <div className="featuresMain hight-price-sixth-max pb-4 border-bottom-price">
          <ul className="features-ul mt-4 mb-5">
            <li className="features-list d-flex justify-content-between">
              Domain management
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              System languages
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Account settings
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              User access management
              <img className="pe-3" src={MoreInfo} />
            </li>
          </ul>
        </div>

        <div className="d-flex align-items-end pt-4 pb-2 mx-4">
          <h3 className="price-heading  mb-1">96 €</h3>
          <p className="price-para px-1 mb-2">/ monthly</p>
        </div>
        <div className="pb-4 border-bottom-none">
          <div className="getStartbtnDiv d-flex align-items-center justify-content-center p-2 mx-4 btn btn-primary col-10">
            <span className="getStartbtnSpan">
              Get started — it's free
            </span>
          </div>
          <span className="getstart-span">
            No credit card required. Cancel anytime.
          </span>
        </div>
      </div>
    </div>

    <div className="col-12 p-0 col-lg-3 my-3 my-lg-0">
      <div className="pricingInfoMain-pricing border-left-none border-top-none">
        <div className="height-price-head-max">
          <div className="startup-main pt-5 pb-4 mx-0 border-bottom-price  mx-lg-4 px-4 px-lg-0">
            <h3 className="startup-heading">Enterprise</h3>
            <p className="startup-para">Power Up your business</p>
          </div>
          <div className="d-flex align-items-end pt-4 pb-2 mx-4">
            <h3 className="price-heading  mb-1">260 €</h3>
            <p className="price-para px-1 mb-2">/ monthly</p>
          </div>
          <div className="pb-4">
            <div className="getStartbtnDiv d-flex align-items-center justify-content-center p-2 mx-4 btn btn-primary col-10">
              <span className="getStartbtnSpan">
                Get started — it's free
              </span>
            </div>
            <span className="getstart-span">
              No credit card required. Cancel anytime.
            </span>
          </div>
        </div>
        <div className="featuresMain hight-price-first-max pb-4 border-bottom-price">
          <ul className="features-ul mt-4 mb-5">
            <li className="features-list d-flex justify-content-between">
              Theme builder
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Design editor
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Site structure
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Pages
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Blogs/Articles
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Banners
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Galleries
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              SEO
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Multimedia
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Drive 50GB
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Languages
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Sitemap
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Picture editor
              <img className="pe-3" src={MoreInfo} />
            </li>
          </ul>
        </div>

        <div className="featuresMain hight-price-second-max pb-4 border-bottom-price">
          <ul className="features-ul mt-4 mb-5">
            <li className="features-list d-flex justify-content-between">
              Products
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Catalogs
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Categories
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Statuses
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Orders
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Carts
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Invoices
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Atributes
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Warehouses
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Logistic
              <img className="pe-3" src={MoreInfo} />
            </li>
          </ul>
        </div>

        <div className="featuresMain hight-price-third-max pb-4 border-bottom-price">
          <ul className="features-ul pt-4 mb-5">
            <li className="features-list d-flex justify-content-between">
              Emailing — 10000 daily
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Loyalty program
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              SMS gateway — 5000 daily
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              CRM
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Social networks
              <img className="pe-3" src={MoreInfo} />
            </li>
          </ul>
        </div>

        <div className="featuresMain hight-price-fourth-max pb-4 border-bottom-price">
          <ul className="features-ul mt-4 mb-5">
            <li className="features-list d-flex justify-content-between">
              Promo codes
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Promo events
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Discounts
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Loyalty program
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Gift cards
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Calendar
              <img className="pe-3" src={MoreInfo} />
            </li>
          </ul>
        </div>

        <div className="featuresMain hight-price-fifth-max pb-4 border-bottom-price">
          <ul className="features-ul mt-4 mb-5">
            <li className="features-list d-flex justify-content-between">
              Customers
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Sales
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Orders
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Traffic, acquisition, real-time, conversion
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Geo
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Loyalty program
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Campaigns
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Logistics
              <img className="pe-3" src={MoreInfo} />
            </li>
          </ul>
        </div>

        <div className="featuresMain hight-price-sixth-max pb-4 border-bottom-price">
          <ul className="features-ul mt-4 mb-5">
            <li className="features-list d-flex justify-content-between">
              Domain management
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              System languages
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Account settings
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              User access management
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Roles
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Tasks
              <img className="pe-3" src={MoreInfo} />
            </li>
            <li className="features-list d-flex justify-content-between">
              Customisation
              <img className="pe-3" src={MoreInfo} />
            </li>
          </ul>
        </div>

        <div className="d-flex align-items-end pt-4 pb-2 mx-4">
          <h3 className="price-heading  mb-1">260 €</h3>
          <p className="price-para px-1 mb-2">/ monthly</p>
        </div>
        <div className="pb-4 border-bottom-none">
          <div className="getStartbtnDiv d-flex align-items-center justify-content-center p-2 mx-4 btn btn-primary col-10">
            <span className="getStartbtnSpan">
              Get started — it's free
            </span>
          </div>
          <span className="getstart-span">
            No credit card required. Cancel anytime.
          </span>
        </div>
      </div>
    </div>
  </div>
  </>
  )
}
