import React from "react";
import "./user.css";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo_symbol.svg";
import Google from "../../assets/img/google-icon.png";
import Facebook from "../../assets/img/facebookLogin.png";

const Login = () => {
  return (
    <>
      <div
        style={{
          height: "100vh",
          width: "100%",
          margin: "0",
          padding: "0",
          background: "#F2F2F2",
        }}
      >
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6">
              <div className="login-main text-start py-5 position-relative">
                <img className="logo-login px-sm-5 px-1" src={Logo} />
                <h2 className="login-head px-2 px-sm-5">Log in</h2>
                <p className="login-para px-2 px-sm-5">
                  Don’t have an account <Link to="/get-started">Sign Up</Link>
                </p>
                <div className="email-input-main d-flex flex-column">
                  <label className="email-label mb-1 px-2 px-sm-5">
                    Your email address
                  </label>
                  <input
                    className="email-input p-3 mb-3 mx-1 mx-sm-5"
                    type="email"
                  />
                  <button className="btn btn-primary headerButton mb-2 px-5 py-3 mx-1 mx-sm-5">
                    Continue wih your mail
                  </button>

                  {/* Divider */}
                  <div className="divider my-3 d-flex align-items-center">
                    <hr className="flex-grow-1" />
                    <span className="mx-2 text-muted">Or</span>
                    <hr className="flex-grow-1" />
                  </div>

                  <button className="button-login-google mb-2 px-5 py-3 btn btn-light mx-1 mx-sm-5">
                    <img className="me-2" src={Google} />
                    Sign in with Google
                  </button>
                  <button className="button-login-google mb-2 px-5 py-3 btn btn-light mx-1 mx-sm-5">
                    <img className="me-2" src={Facebook} />
                    Sign in with Facebook
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
