import React, { useState } from "react";
import BlogSection from "../../LandingPage/BlogSection";

const Faqs = () => {
  const [activeIndexes, setActiveIndexes] = useState({}); // Manage active indexes per category

  const faqs = [
    {
      category: "General questions",
      question: "What is Mozayc and how does it work?",
      answer: "Mozayc is a platform that...",
    },
    {
      category: "General questions",
      question: "How much does Mozayc cost?",
      answer:
        "If you already have an ecommerce website or are using a different ecommerce platform for your business, you can use the Store Importer app to import your products, customers, and orders data to Shopify.",
    },
    {
      category: "General questions",
      question: "How long are your contracts?",
      answer: "Our contracts are flexible and...",
    },
    {
      category: "General questions",
      question: "Can I cancel my account at any time?",
      answer: "Yes, you can cancel your account...",
    },
    {
      category: "General questions",
      question: "Can I change my plan later on?",
      answer: "You can upgrade or downgrade your plan...",
    },
    {
      category: "General questions",
      question: "Do you offer any discounts?",
      answer: "You can upgrade or downgrade your plan...",
    },
    {
      category: "General questions",
      question: "In what countries can I use Mozayc?",
      answer: "You can upgrade or downgrade your plan...",
    },
    {
      category: "General questions",
      question: "Is Mozayc PCI Compliant or PCI Certified?",
      answer: "You can upgrade or downgrade your plan...",
    },
    {
      category: "Payment questions",
      question: "Are there any transaction fees?",
      answer: "You can upgrade or downgrade your plan...",
    },
    {
      category: "Payment questions",
      question: "What is a third-party payment provider?",
      answer: "You can upgrade or downgrade your plan...",
    },
    {
      category: "Payment questions",
      question: "Are there any credit card fees?",
      answer: "You can upgrade or downgrade your plan...",
    },
    {
      category: "Store setup questions",
      question: "Is there a setup fee?",
      answer: "You can upgrade or downgrade your plan...",
    },
    {
      category: "Store setup questions",
      question: "I'm looking to switch to Mozayc. How do I get my data over?",
      answer: "You can upgrade or downgrade your plan...",
    },
    {
      category: "Store setup questions",
      question: "Can I use my own domain name with Mozayc?",
      answer: "You can upgrade or downgrade your plan...",
    },
    {
      category: "Store setup questions",
      question: "Do I get free web hosting when I open an online store?",
      answer: "You can upgrade or downgrade your plan...",
    },
    {
      category: "Store setup questions",
      question: "What are your bandwidth fees?",
      answer: "You can upgrade or downgrade your plan...",
    },
  ];

  // Extract unique categories
  const categories = [...new Set(faqs.map((faq) => faq.category))];

  const toggleFAQ = (category, index) => {
    setActiveIndexes((prevState) => ({
      ...prevState,
      [category]: prevState[category] === index ? null : index, // Toggle active index for the category
    }));
  };

  return (
    <>
      <div className="container mt-md-5">
        <div className="row">
          <div className="col-12">
            <div className="pt-3 pt-md-5">
              <h3 className="faqs-head pb-4 mb-3">
                Frequently Asked Questions
              </h3>
              {/* Group FAQs by category */}
              {categories.map((category) => (
                <div key={category} className="faq-category-section pb-5">
                  <h3 className="faqs-sub-heading pb-3">{category}</h3>
                  <div className="faq-section">
                    {faqs
                      .filter((faq) => faq.category === category)
                      .map((faq, index) => (
                        <div key={index} className="faq-item">
                          <div
                            className={`faq-question py-2 px-4 ${
                              activeIndexes[category] === index
                                ? "active-faq"
                                : ""
                            }`}
                            onClick={() => toggleFAQ(category, index)}
                          >
                            <span
                              className={`plus-minus-faqs pe-5 ${
                                activeIndexes[category] === index
                                  ? "active-icon"
                                  : ""
                              }`}
                            >
                              {activeIndexes[category] === index ? "-" : "+"}
                            </span>
                            <div>
                              <span>{faq.question}</span>
                              {activeIndexes[category] === index && (
                                <div className="faq-answer pt-2">
                                  <p className="faq-answer-para">
                                    {faq.answer}
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <BlogSection />
    </>
  );
};

export default Faqs;
