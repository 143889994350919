import React from "react";
import "./user.css";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo_symbol.svg";
import Google from "../../assets/img/google-icon.png";
import Facebook from "../../assets/img/facebookLogin.png";

const StepOne = () => {
  return (
    <>
      <div
        style={{
          height: "150vh",
          width: "100%",
          margin: "0",
          padding: "0",
          background: "#F2F2F2",
        }}
      >
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
              <div className="login-main text-start py-5 position-relative">
                {/* <img className="logo-login px-sm-5 px-1" src={Logo} /> */}
                <h2 className="login-head px-2 px-sm-5">
                  Let’s get started. Which of these best describes you?
                </h2>
                <p className="login-para px-2 px-sm-5">
                  Based on the answers we will setup the initial features of
                  your admin panel.
                </p>
                <div className="email-input-main d-flex flex-column">
                  <label className="email-input p-4 mb-3 mx-1 mx-sm-5">
                    <input
                      type="radio"
                      name="business-stage"
                      value="just-starting"
                      className="me-2"
                    />
                    I'm just starting my business
                  </label>

                  <label className="email-input p-4 mb-3 mx-1 mx-sm-5">
                    <input
                      type="radio"
                      name="business-stage"
                      value="not-online"
                      className="me-2"
                    />
                    I’m already selling, but not online
                  </label>

                  <label className="email-input p-4 mb-5 mx-1 mx-sm-5">
                    <input
                      type="radio"
                      name="business-stage"
                      value="selling-online"
                      className="me-2"
                    />
                    I’m already selling online or in person
                  </label>
                  <div className="d-flex justify-content-end">
                    <button className="col-2 btn btn-transparent skipButton me-2">
                      Skip
                    </button>
                    <button className="col-2 btn btn-primary nextButton mx-1 me-sm-5">
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepOne;
