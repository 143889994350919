import React, { useState } from "react";
import ArrowDown from "../../assets/img/all-feature-arrow-down.svg";

const AllFeatures = () => {
  const [activeFeature, setActiveFeature] = useState("All Features");

  // Handle active category change
  const handleFeatureClick = (feature) => {
    setActiveFeature(feature);
  };

  const allFeatures = [
    {
      heading: "Theme builder",
      para: "Full featured CMS with SEO and languages management will take care that your clients will quickly find you on the internet whatever language they speak.",
    },
    {
      heading: "Design editor",
      para: "Full featured CMS with SEO and languages management will take care that your clients will quickly find you on the internet whatever language they speak.",
    },
    {
      heading: "Site structure",
      para: "Full featured CMS with SEO and languages management will take care that your clients will quickly find you on the internet whatever language they speak.",
    },
    {
      heading: "Listing & Pages",
      para: "Full featured CMS with SEO and languages management will take care that your clients will quickly find you on the internet whatever language they speak.",
    },
    {
      heading: "Blogs & Articles",
      para: "Full featured CMS with SEO and languages management will take care that your clients will quickly find you on the internet whatever language they speak.",
    },
    {
      heading: "Banners",
      para: "Full featured CMS with SEO and languages management will take care that your clients will quickly find you on the internet whatever language they speak.",
    },
    {
      heading: "Product Galleries",
      para: "Full featured CMS with SEO and languages management will take care that your clients will quickly find you on the internet whatever language they speak.",
    },
    {
      heading: "Picture editor",
      para: "Full featured CMS with SEO and languages management will take care that your clients will quickly find you on the internet whatever language they speak.",
    },
    {
      heading: "Multimedia",
      para: "Full featured CMS with SEO and languages management will take care that your clients will quickly find you on the internet whatever language they speak.",
    },
    {
      heading: "SEO",
      para: "Full featured CMS with SEO and languages management will take care that your clients will quickly find you on the internet whatever language they speak.",
    },
    {
      heading: "Drive (files storage)",
      para: "Full featured CMS with SEO and languages management will take care that your clients will quickly find you on the internet whatever language they speak.",
    },
  ];

  return (
    <>
      <section className="allFeaturesSection mx-2 p-2 p-sm-0 p-md-0 pt-4">
        <div className="container-fluid mx-2 border-botton-all-features-Likns">
          <div className="row">
            <div className="col-12">
              <div className="allFeaturesLinks">
                <ul className="d-flex justify-content-center align-items-center flex-wrap px-0 mb-0">
                  {[
                    "All Features",
                    "Mozayc CMS",
                    "eCommerce",
                    "Marketing",
                    "Promotion",
                    "Analytics",
                    "Administration",
                  ].map((feature) => (
                    <li
                      key={feature}
                      className={`mx-2 mx-sm-0 py-sm-2 py-lg-5 px-md-3 feature-link ${
                        activeFeature === feature ? "active-feature" : ""
                      }`}
                      onClick={() => handleFeatureClick(feature)}
                    >
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="container pt-5">
          <div className="row py-5">
            <div className="col-12 col-md-6">
              <h1 className="allFeatureCardsHeading">All features</h1>
            </div>
            <div className="col-12 col-md-6 mb-5">
              <p className="allFeatureCardsPara">
                Mozayc gives you all the tools you need to start and grow your
                e-shop, as well as migrate your existing e-shop and to boost
                your work effectiveness and increase your sales.
              </p>
              <p className="allFeatureCardsPara">
                Manage with easy web content and design, SEO, products,
                warehouse, payments, logistics, orders, invoicing...
              </p>
            </div>

            {allFeatures.length > 0 &&
              allFeatures.map((feature, index) => (
                <div className="col-12 col-md-6 mb-4">
                  <div className="py-5 px-3 p-mb-5 pb-4 allFeature-info d-flex position-relative">
                    {(feature.heading === "Blogs & Articles" ||
                      feature.heading === "Design editor") && (
                      <span className="new-tag py-1 px-2">New</span>
                    )}
                    <div>
                      <img
                        className="all-features-arrow-img d-flex justify-content-start"
                        src={ArrowDown}
                        alt="Arrow"
                      />
                    </div>
                    <div className="px-3 allFeature-info-content d-flex flex-column justify-content-start">
                      <div key={index} className="mb-4">
                        <h1 className="allFeature-info-heading">
                          {feature.heading}
                        </h1>
                        <p className="allFeature-info-Para mb-0">
                          {feature.para}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default AllFeatures;
