import React from "react";
import "./Footer.css";
import LogoSymbol from "../assets/img/logo_symbol.svg";
import Planet from "../assets/img/icon_planet.svg";
import FB from '../assets/img/soc_fb.svg';
import Insta from '../assets/img/soc_insta.svg';
import Youtube from "../assets/img/soc_youtube.svg";

const Footer = () => {
  return (
    <footer id="footer" className="footer light-background">
      {/* Footer upper div */}
      <div className="footer-upper-div mx-3 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-4">
              <h1 className="footer-upper-heding">10k+</h1>
              <p className="footer-upper-para">Current Mozayc users</p>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <h1 className="footer-upper-heding">8k+</h1>
              <p className="footer-upper-para">Eshops from Mozayc</p>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <h1 className="footer-upper-heding">34</h1>
              <p className="footer-upper-para">countries represented</p>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Middle Div */}
      <div className="footer-middle-div my-3 mx-3 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-6 col-sm-6 col-md-3">
              <h1 className="footer-middle-heding mb-5">Product</h1>
              <p className="footer-middle-para">Discover features</p>
              <p className="footer-middle-para">Customers</p>
              <p className="footer-middle-para">Weekly sessions</p>
              <p className="footer-middle-para">Free trials and demo</p>
              <p className="footer-middle-para">What's next?</p>
            </div>
            <div className="col-6 col-sm-6 col-md-3">
              <h1 className="footer-middle-heding mb-5">Product</h1>
              <p className="footer-middle-para">Discover features</p>
              <p className="footer-middle-para">Customers</p>
              <p className="footer-middle-para">Weekly sessions</p>
              <p className="footer-middle-para">Free trials and demo</p>
              <p className="footer-middle-para">What's next?</p>
            </div>
            <div className="col-6 col-sm-6 col-md-2 mt-5 mt-md-0">
              <h1 className="footer-middle-heding mb-5">Product</h1>
              <p className="footer-middle-para">Discover features</p>
              <p className="footer-middle-para">Customers</p>
              <p className="footer-middle-para">Weekly sessions</p>
              <p className="footer-middle-para">Free trials and demo</p>
              <p className="footer-middle-para">What's next?</p>
            </div>
            <div className="col-12 col-sm-6 col-md-4 mt-5 mt-md-0">
              <div className="footer-mddle-last px-3 pb-5 pt-3 position-relative">
                <img className="footerLogo" src={LogoSymbol} />
                <h1 className="footer-mddle-last-heading p-4">
                  Grow your
                  <br />
                  ecommerce here
                </h1>
                <div className="getStartbtnDiv d-flex align-items-center justify-content-center p-2 mx-4 btn btn-primary col-10">
                  <span className="getStartbtnSpan">
                    Get started — it's free
                  </span>
                </div>
                <span className="getstart-span">
                  No credit card required. Cancel anytime.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Last Div */}
      <div className="footer-Last-div mx-3">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="d-flex align-items-start justify-content-start">
              {/* <div>
              <button className="btn btn-light footer-last-btn me-2 px-3 btn btn-light">
                <span className="footer-last-btn-span">
              <img className="mx-1" src={Planet} alt="Planet Icon" />
                English
                </span>
              </button>
              </div> */}
              <div className="d-flex my-3 my-md-0 col-md-5">
                <p className="footerCopyRightPara mb-0">© 2023 Mozayc.com. All rights reserved
                Webdesign by Kennymax Visual Designer </p>
              </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="d-flex align-items-center">
                <div>
              <button className="btn btn-light footer-last-btn mx-2 px-2 py-1 btn btn-light">
                <span className="footer-last-btn-span">
              <img className="mx-3" src={FB} alt="Planet Icon" />
                </span>
              </button>
              </div>
                <div>
              <button className="btn btn-light footer-last-btn mx-2 px-2 py-1 btn btn-light">
                <span className="footer-last-btn-span">
              <img className="mx-3" src={Insta} alt="Planet Icon" />
                </span>
              </button>
              </div>
                <div>
              <button className="btn btn-light footer-last-btn mx-2 px-2 py-1 btn btn-light">
                <span className="footer-last-btn-span">
              <img className="mx-3" src={Youtube} alt="Planet Icon" />
                </span>
              </button>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
