import React from "react";
import InfiniteImg from "../../assets/img/infinity.jpg";
import AddToCartImg from "../../assets/img/addToCart.jpg";
import RectAngle from "../../assets/img/rectAngleBusiness.svg";
import RectAngleBlue from "../../assets/img/RectangleBlue.svg";
import RectAnglePink from "../../assets/img/RectanglePink.svg";
import Utilities from "../../assets/img/Utilities.jpg";
import AddToCartLast from "../../assets/img/addToCartLast.jpg";

const BusinessSection = () => {
  return (
    <>
      <section className="business-section mt-0 pt-0">
        <div className="container">
          {/* Business Header Image */}
          <div className="row">
            <div className="">
              <img className="businessHeadImage" src={InfiniteImg} />
            </div>
          </div>

          {/* Business Heading */}
          <div className="row justify-content-center mt-3">
            <div className="businessHeadingDiv col-12 col-md-8">
              <h1 className="businessHeading headerHeading">
                Take your business where you need
              </h1>
              <p className="businessPara headingPara">
                Build and scale with confidence. From powerful site
                infrastructure to advanced business solutions — we’ve got you
                covered.
              </p>
            </div>
          </div>

          {/* Business content divs */}
          <div className="row justify-content-start">
            <div className="col-12 col-lg-6">
              <img className="addToCartImg" src={AddToCartImg} />
            </div>
            <div className="col-12 col-lg-6 px-5">
              <img
                className="d-flex justify-content-start rectangleImg my-3 pt-2 pb-2"
                src={RectAngle}
              />
              <h3 className="Business-content-Head">
                All-in-one e-commerce management system
              </h3>
              <p className="Business-content-Para text-start">
                Mozayc gives you all the tools you need to start and grow your
                e-shop, as well as migrate your existing e-shop and to boost
                your work effectiveness and increase your sales. Manage with
                easy web content and design, SEO, products, warehouse, payments,
                logistics, orders, invoicing...
              </p>
            </div>
          </div>

          <div className="row justify-content-start">
            <div className="col-12 col-lg-6 px-5 order-last order-lg-first">
              <img
                className="d-flex justify-content-start rectangleImg my-3 pt-2 pb-2"
                src={RectAnglePink}
              />
              <h3 className="Business-content-Head">
                Enterprise business tools as easy as powerful
              </h3>
              <p className="Business-content-Para text-start">
                Managing high level online business without large and expensive
                team of professionals. Process automation, individual setup
                option and user friendly administration interface make every
                online store an enterprise business. For business beginners
                Mozayc offers a step-by-step assistance system, Mozay e-commerce
                academy and of course our local professional partners
              </p>
            </div>
            <div className="col-12 col-lg-6 order-first order-lg-last">
              <img className="addToCartImg" src={Utilities} />
            </div>
          </div>

          <div className="row justify-content-start">
            <div className="col-12 col-lg-6">
              <img className="addToCartImg" src={AddToCartLast} />
            </div>
            <div className="col-12 col-lg-6 px-5">
              <img
                className="d-flex justify-content-start rectangleImg my-3 pt-2 pb-2"
                src={RectAngleBlue}
              />
              <h3 className="Business-content-Head">
                Much more than a tool for managing your eshop
              </h3>
              <p className="Business-content-Para text-start">
                Mozayc is a business guide, process automation system, marketer,
                analyst, logistical support system, payment system manager,
                online business academy and lots more. And? Mozayc speaks your
                language.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BusinessSection;
