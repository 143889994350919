import React from "react";
import "./Updates.css";
import { Link } from "react-router-dom";
import BlogSection from "../../LandingPage/BlogSection";

const Updates = () => {
  return (
    <>
      <div className="container my-5 pb-5">
        <div className="row">
          <div className="col-12">
            <div className="updates-heading-main py-5">
              <h3 className="updates-heading">Updates & Releases</h3>
            </div>
          </div>

          <div className="col-12">
            <div className="updates-heading-main pt-5 pb-4">
              <p className="updates-para">Dec 9, 2022 · New Features</p>
              <h3 className="updates-features-heading">
                Promotion — HTML Embed Now Available
              </h3>
              <p className="updates-para col-12 col-md-7">
                You can now add HTML code and iFrames to your ADI site. Embed
                services like Spotify, Amazon Ad Widget, PayPal button, as well
                as external sites.{" "}
                <Link className="fw-bold" to="#">
                  Learn more
                </Link>
              </p>
            </div>
          </div>

          <div className="col-12">
            <div className="updates-heading-main pt-5 pb-4">
              <p className="updates-para">Dec 9, 2022 · New Features</p>
              <h3 className="updates-features-heading">
                Marketing — New Editing Access
              </h3>
              <p className="updates-para col-12 col-md-7">
                Creating your site with Mozayc? Now you can right-click on the
                blue overlay to access all the editing tools.
              </p>
            </div>
          </div>

          <div className="col-12">
            <div className="updates-heading-main pt-5 pb-4">
              <p className="updates-para">Dec 9, 2022 · New Features</p>
              <h3 className="updates-features-heading">
                Editor — Improved Editing Experience
              </h3>
              <p className="updates-para col-12 col-md-7">
                Creating your site with Mozayc editor? Actions like Edit and
                Design are now on top of each section and there are more ways to
                access the section design editor.{" "}
                <Link className="fw-bold" to="#">
                  Learn more
                </Link>
              </p>
            </div>
          </div>

          <div className="col-12">
            <div className="updates-heading-main pt-5 pb-4">
              <p className="updates-para">Dec 9, 2022 · New Features</p>
              <h3 className="updates-features-heading">
                Mozayc CMS — Expanded Access to Photo Studio
              </h3>
              <p className="updates-para col-12 col-md-7">
                Creating your site with Mozayc? Now you can right-click on the
                blue overlay to access all the editing tools.
              </p>
            </div>
          </div>

          <div className="col-12">
            <div className="updates-heading-main pt-5 pb-4">
              <p className="updates-para">Dec 9, 2022 · New Features</p>
              <h3 className="updates-features-heading">
                Promotion — HTML Embed Now Available
              </h3>
              <p className="updates-para col-12 col-md-7">
                You can now add HTML code and iFrames to your ADI site. Embed
                services like Spotify, Amazon Ad Widget, PayPal button, as well
                as external sites.{" "}
                <Link className="fw-bold" to="#">
                  Learn more
                </Link>
              </p>
            </div>
          </div>

          <div className="col-12">
            <div className="updates-heading-main pt-5 pb-4">
              <p className="updates-para">Dec 9, 2022 · New Features</p>
              <h3 className="updates-features-heading">
                Editor — New Editing Access
              </h3>
              <p className="updates-para col-12 col-md-7">
                Creating your site with Mozayc? Now you can right-click on the
                blue overlay to access all the editing tools.
              </p>
            </div>
          </div>

          <div className="col-12">
            <div className="updates-heading-main pt-5 pb-4">
              <p className="updates-para">Dec 9, 2022 · New Features</p>
              <h3 className="updates-features-heading">
                Templates — Improved Editing Experience
              </h3>
              <p className="updates-para col-12 col-md-7">
                Creating your site with Mozayc editor? Actions like Edit and
                Design are now on top of each section and there are more ways to
                access the section design editor.{" "}
                <Link className="fw-bold" to="#">
                  Learn more
                </Link>
              </p>
            </div>
          </div>

          <div className="col-12">
            <div className="updates-heading-main pt-5 pb-4">
              <p className="updates-para">Dec 9, 2022 · New Features</p>
              <h3 className="updates-features-heading">
                Marketing — Expanded Access to Photo Studio
              </h3>
              <p className="updates-para col-12 col-md-7">
                Creating your site with Mozayc? Now you can right-click on the
                blue overlay to access all the editing tools.
              </p>
            </div>
          </div>
        </div>
        <div className="theme-list-button-main pt-4 d-flex justify-content-start mt-3 mb-5">
          <button className="theme-list-button me-2 active">1</button>
          <button className="theme-list-button me-2">2</button>
          <button className="theme-list-button me-2">3</button>
        </div>
      </div>

      <BlogSection />
    </>
  );
};

export default Updates;
