import React, { useState } from "react";
import MoreInfo from "../../assets/img/more-info.svg";
import YearlyPrice from "../../assets/img/Combined Shape.svg";
import Magic from "../../assets/img/magicPrice.svg";
import Verify from "../../assets/img/verify.svg";
import Shape from "../../assets/img/Shape.svg";
import Lock from "../../assets/img/lock.svg";
import { Monthly } from "./Monthly";
import { Yearly } from "./Yearly";

const OurEarlyBid = () => {
  const [activePlan, setActivePlan] = useState("monthly"); // Default is "monthly"

  return (
    <>
      <section className="business-section my-5 mt-md-0 pt-0">
        <div className="container">
          {/* Business Heading */}
          <div className="row justify-content-center mt-5">
            <div className="businessHeadingDiv col-12 col-md-8">
              <h1 className="businessHeading headerHeading">
                Check out
                <br />
                our early bird offer
              </h1>
              <p className="businessPara headingPara">
                Start a free trial, or get started with our free plan.
              </p>
            </div>
          </div>

          <div className="row justify-content-center pb-3">
            <div className="pricingButtonsDiv mb-3 col-10 col-md-8 col-lg-4">
              <button
                className={`col-6 p-2 monthly-btn ${
                  activePlan === "monthly" ? "active-btn" : ""
                }`}
                onClick={() => setActivePlan("monthly")}
              >
                Monthly
              </button>
              <button
                className={`col-6 p-2 yearly-btn ${
                  activePlan === "yearly" ? "active-btn" : ""
                }`}
                onClick={() => setActivePlan("yearly")}
              >
                <img className="me-2" src={YearlyPrice} />
                Yearly -20%
              </button>
            </div>
          </div>

          {/* Conditional Rendering of Components */}
          {activePlan === "monthly" ? <Monthly /> : <Yearly />}
        </div>

        <div className="container mb-3 border-bottom-price">
          <div className="row justify-content-center align-items-center py-4 mx-4">
            <div className="col-12 col-md-6 col-lg-4 mt-3 mt-lg-0">
              <p className="dec-para mb-0 justify-content-center d-flex align-items-center">
                <img src={Verify} className="me-2" />
                <span className="veryfy-span mx-1">30 days </span> money back
                guarantee
              </p>
            </div>

            <div className="col-12 col-md-6 col-lg-4 mt-3 mt-lg-0">
              <p className="dec-para mb-0 justify-content-center d-flex align-items-center">
                <img src={Shape} className="me-2" />
                <span className="veryfy-span mx-1">Support </span> teams across
                the world
              </p>
            </div>

            <div className="col-12 col-md-6 col-lg-4 mt-3 mt-lg-0">
              <p className="dec-para mb-0 justify-content-center d-flex align-items-center">
                <img src={Lock} className="me-2" />
                <span className="veryfy-span mx-1">Safe and secure </span>{" "}
                online payment
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurEarlyBid;
