import React from 'react'
import Checkout from "../../assets/img/check.svg";

export const Monthly = () => {
  return (
    <>
        <div className="row mt-5">
            <div className="col-12 col-md-4">
            <div className="pricingInfoMain">
                <div className="startup-main pt-3 pb-2 mx-0 border-bottom-price mx-4">
                <h3 className="startup-heading">Startup</h3>
                <p className="startup-para">Hit the ground running</p>
                </div>
                <div className="d-flex align-items-end pt-3 pb-2 mx-4">
                <h3 className="price-heading  mb-1">24 €</h3>
                <p className="price-para px-1 mb-2">/ monthly</p>
                </div>
                <div>
                <div className="getStartbtnDiv d-flex align-items-center justify-content-center p-2 mx-4 btn btn-primary col-10">
                    <span className="getStartbtnSpan">
                    Get started — it's free
                    </span>
                </div>
                <span className="getstart-span">
                    No credit card required. Cancel anytime.
                </span>
                </div>
                <div className="featuresMain pb-4">
                <ul className="features-ul mt-4 mb-5">
                    <li className="features-list">
                    <img className="pe-3" src={Checkout} />
                    CMS
                    </li>
                    <li className="features-list">
                    <img className="pe-3" src={Checkout} />
                    E-commerce
                    </li>
                    <li className="features-list">
                    <img className="pe-3" src={Checkout} />
                    Marketing
                    </li>
                    <li className="features-list">
                    <img className="pe-3" src={Checkout} />
                    24 features included
                    </li>
                    <li className="features-list">
                    <img className="pe-3" src={Checkout} />
                    Basic reports
                    </li>
                    <li className="features-list">
                    <img className="pe-3" src={Checkout} />2 staff accounts
                    </li>
                </ul>
                </div>
            </div>
            </div>

            <div className="col-12 col-md-4">
            <div className="pricingInfoMain">
                <div className="startup-main pt-3 pb-2 mx-0 border-bottom-price mx-4">
                <h3 className="startup-heading">Growing</h3>
                <p className="startup-para">Power Up your business</p>
                </div>
                <div className="d-flex align-items-end pt-3 pb-2 mx-4">
                <h3 className="price-heading  mb-1">96 €</h3>
                <p className="price-para px-1 mb-2">/ monthly</p>
                </div>
                <div>
                <div className="getStartbtnDiv d-flex align-items-center justify-content-center p-2 mx-4 btn btn-primary col-10">
                    <span className="getStartbtnSpan">
                    Get started — it's free
                    </span>
                </div>
                <span className="getstart-span">
                    No credit card required. Cancel anytime.
                </span>
                </div>
                <div className="featuresMain pb-4">
                <ul className="features-ul mt-4 mb-5">
                    <li className="features-list">
                    <img className="pe-3" src={Checkout} />
                    CMS
                    </li>
                    <li className="features-list">
                    <img className="pe-3" src={Checkout} />
                    E-commerce
                    </li>
                    <li className="features-list">
                    <img className="pe-3" src={Checkout} />
                    Marketing
                    </li>
                    <li className="features-list">
                    <img className="pe-3" src={Checkout} />
                    56 features included
                    </li>
                    <li className="features-list">
                    <img className="pe-3" src={Checkout} />
                    Professional reports
                    </li>
                    <li className="features-list">
                    <img className="pe-3" src={Checkout} />5 staff accounts
                    </li>
                </ul>
                </div>
            </div>
            </div>

            <div className="col-12 col-md-4">
            <div className="pricingInfoMain">
                <div className="startup-main pt-3 pb-2 mx-0 border-bottom-price mx-4">
                <h3 className="startup-heading">Enterprise</h3>
                <p className="startup-para">Power Up your business</p>
                </div>
                <div className="d-flex align-items-end pt-3 pb-2 mx-4">
                <h3 className="price-heading  mb-1">260 €</h3>
                <p className="price-para px-1 mb-2">/ monthly</p>
                </div>
                <div>
                <div className="getStartbtnDiv d-flex align-items-center justify-content-center p-2 mx-4 btn btn-primary col-10">
                    <span className="getStartbtnSpan">
                    Get started — it's free
                    </span>
                </div>
                <span className="getstart-span">
                    No credit card required. Cancel anytime.
                </span>
                </div>
                <div className="featuresMain pb-4">
                <ul className="features-ul mt-4 mb-5">
                    <li className="features-list">
                    <img className="pe-3" src={Checkout} />
                    CMS
                    </li>
                    <li className="features-list">
                    <img className="pe-3" src={Checkout} />
                    E-commerce
                    </li>
                    <li className="features-list">
                    <img className="pe-3" src={Checkout} />
                    Marketing
                    </li>
                    <li className="features-list">
                    <img className="pe-3" src={Checkout} />
                    All 108 features included
                    </li>
                    <li className="features-list">
                    <img className="pe-3" src={Checkout} />
                    Custom report builder
                    </li>
                    <li className="features-list">
                    <img className="pe-3" src={Checkout} />
                    15 staff accounts
                    </li>
                    <li className="features-list">
                    <img className="pe-3" src={Checkout} />
                    Administration
                    </li>
                </ul>
                </div>
            </div>
            </div>
            <div className="pt-3 pb-4">
            <p className="dec-para text-center">
                All plans include 60 days Galaxy plan trial free of charge.
            </p>
            </div>
        </div>
    </>
  )
}