import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom"; // Import useLocation hook
import logo from "../assets/img/logo-features-header.svg";
import Planet from "../assets/img/planet-features.svg";
import "./HeaderFeatures.css";

const HeaderFeatures = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [headerBgColor, setHeaderBgColor] = useState("");
  const location = useLocation(); // Get current location
  const Navigate = useNavigate();

  // Toggle the hamburger menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const loginClick = () => {
    Navigate("/login");
    setIsMenuOpen(false);
  };

  const logoutClick = () => {
    Navigate("/get-started");
    setIsMenuOpen(false);
  };

  // Change header background color on scroll
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setHeaderBgColor("#202037");
      } else {
        setHeaderBgColor("");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Determine active category based on the current route
  const getActiveCategory = () => {
    const path = location.pathname.split("/")[1]; // Get the part of the URL after '/'

    const categoryMap = {
      features: "Features",
      pricing: "Pricing",
      showcases: "Showcases",
      "help-center": "Help Center", // Ensure this matches the route path
      //blog: "Blog",
    };

    return categoryMap[path] || ""; // Return the mapped category or an empty string
  };

  return (
    <header
      id="header"
      className="header py-3 headerFeatures-custom d-flex align-items-center sticky-top"
      style={{
        backgroundColor: headerBgColor,
        transition: "background-color 0.3s",
      }}
    >
      <div className="container-fluid position-relative">
        <nav id="navmenu" className="navmenu text-center">
          <div className="row align-items-center justify-content-between">
            {/* Logo */}
            <div className="col-3">
              <Link to="/" className="logo d-flex align-items-center">
                <img src={logo} alt="Logo" aria-label="Logo" />
              </Link>
            </div>

            {/* Links and Buttons for Larger Screens */}
            <div className="col-5 d-none d-xl-flex justify-content-center">
              <ul className="header-links-list d-flex">
                {[
                  "Features",
                  "Pricing",
                  "Showcases",
                  "Help Center",
                  //"Blog",
                ].map((category) => (
                  <li
                    key={category}
                    className={`header-links header-links-features ${
                      getActiveCategory() === category ? "active-category" : ""
                    }`}
                  >
                    <Link
                      to={`/${category.toLowerCase().replace(/\s+/g, "-")}`} // Dynamic link generation
                    >
                      {category}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-4 d-none d-xl-flex justify-content-end">
              {/* <img className="mx-3" src={Planet} alt="Planet Icon" />
              <button
                className="header-button-login-dark mx-2 btn btn-light"
                onClick={() => loginClick()}
              >
                Login
              </button> */}
              <button
                className="header-button-start mx-2 btn btn-primary"
                onClick={() => logoutClick()}
              >
                Contact Us
              </button>
            </div>

            {/* Hamburger Icon for Small Screens */}
            <div className="col-3 d-xl-none d-flex">
              <button className="hamburger-btn" onClick={toggleMenu}>
                <span
                  className={`hamburger-icon hamburger-icon-features ${
                    isMenuOpen ? "open" : ""
                  }`}
                ></span>
              </button>
            </div>
          </div>

          {/* Dropdown Menu for Small Screens */}
          {isMenuOpen && (
            <div className="dropdown-menu-small d-xl-none">
              <ul className="header-links-list dropdown flex-column">
                {[
                  "Features",
                  "Pricing",
                  "Showcases",
                  "Help Center",
                  //"Blog",
                ].map((category) => (
                  <li
                    key={category}
                    className={`dropdown-links ${
                      getActiveCategory() === category ? "active-category" : ""
                    }`}
                  >
                    <Link to={`/${category.toLowerCase().replace(/\s+/g, "")}`}>
                      {category}
                    </Link>
                  </li>
                ))}
              </ul>
              <div className="col-8 col-sm-4 d-xl-none d-flex flex-column">
                {/* <button
                  className="header-button-login-dark m-2 btn btn-light"
                  onClick={() => loginClick()}
                >
                  Login
                </button> */}
                <button
                  className="header-button-start my-1 mx-2 btn btn-primary"
                  onClick={() => logoutClick()}
                >
                  Contact Us
                </button>
              </div>
            </div>
          )}
        </nav>
      </div>
    </header>
  );
};

export default HeaderFeatures;
