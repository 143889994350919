import React from "react";
import HeaderSectionImg from "../../assets/img/HeaderSectionImage.svg";

const HeaderSection = () => {
  return (
    <>
      <section className="headerSection mb-0 pb-0 pt-0 pt-md-5">
        <div className="container-fluid">
          <div className="row justify-content-center position-relative">
            <div className="col-8 col-sm-6 col-md-6 position-absolute">
              <h3 className="headerHeading">
                Everything you need for your eshop
              </h3>
              <p className="headingPara">
                Build and scale with confidence. From powerful site
                infrastructure to advanced business solutions — we’ve got you
                covered.
              </p>
              <div>
                <button className="btn btn-primary headerButton mb-2 px-4 py-3">
                  Get started — it's free
                </button>
                <p className="headerButtonPara">
                  No credit required. cancle anytime
                </p>
              </div>
            </div>
            <img className="margin-Head-img mt-5 py-2" src={HeaderSectionImg} />
          </div>
        </div>
      </section>
    </>
  );
};

export default HeaderSection;
