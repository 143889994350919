import React from "react";
import HeaderSectionImg from "../../assets/img/HeaderSectionImage.svg";

const FeaturesHeaderSection = () => {
  return (
    <>
      <section className="headerSection featuresHeaderSection mb-0 pb-0">
        <div className="container-fluid pt-md-4">
          <div className="row justify-content-center position-relative mt-3 mt-md-5">
            <div className="col-8 col-sm-7 col-md-7 position-absolute">
              <h3 className="headerHeading featuresHeaderHeding">
                Build your store with powerfull Mozayc tools
              </h3>
              <p className="headingPara featuresHeaderPara">
                Grow your business with hundreds of solutions to solve all your
                logistical, technical, analytic, administration and marketing
                needs.
              </p>
              <div>
                <button className="btn btn-primary headerButton mb-2 py-3">
                  Get started — it's free
                </button>
                <p className="headerButtonPara">
                  No credit required. cancle anytime
                </p>
              </div>
            </div>
            <img
              className="margin-Head-img-features mt-2 py-2"
              src={HeaderSectionImg}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default FeaturesHeaderSection;
