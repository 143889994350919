import React from 'react';
import Desktop from '../../assets/img/desktopView.png';
import Tab from '../../assets/img/tabView.png';
import Mobile from '../../assets/img/mobileView.png';
import ArrowRight from '../../assets/img/arrow_right.png';
import ArrowUpRight from '../../assets/img/arrow_up_white.svg';
import ThemeImg from '../../assets/img/ShowCaseDetail.png';
import { Link } from 'react-router-dom';

const ShowcaseDetails = () => {
  return (
    <>
    <div className="header-showcase py-4">
      {/* Left Section */}
      <div className="header-left d-flex justify-content-start">
        <Link to="/showcases">
        <button className="back-button"><img src={ArrowRight}/></button>
        </Link>
      </div>

      {/* Center Section */}
      <div className="header-center">
        <button className="device-button active"><img src={Desktop}/></button>
        <button className="device-button"><img src={Tab}/></button>
        <button className="device-button"><img src={Mobile}/></button>
      </div>

      {/* Right Section */}
      <div className="header-right">
        <button className="view-demo btn btn-light px-4 py-2 me-2">View demo</button>
        <button className="try-design btn btn-primary px-3 py-2">Try this design <img src={ArrowUpRight}/></button>
      </div>
    </div>

    <div className='container-fluid pb-5' style={{background: "#f2f2f2"}}>
        <div className='container pb-5'>
        <div className='row justify-content-center'>
            <div className='showcase-image-main m-5 p-5'>
                <img className='w-100 h-100' src={ThemeImg} />
            </div>
        </div>
        </div>
    </div>
    </>
  )
}

export default ShowcaseDetails
