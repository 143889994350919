import React, { useEffect } from "react";
import Oval from "../../assets/img/Oval.svg";
import Bitmap from "../../assets/img/Bitmap.png";
import ArrowUp from '../../assets/img/arrow_up.svg';
import { Carousel as BootstrapCarousel } from "bootstrap";

const ReviewSlider = () => {
  useEffect(() => {
    const carouselElement = document.querySelector('.carousel');
    const carouselItems = document.querySelectorAll('.carousel-indicators-costom button');
  
    const updateActiveDot = () => {
      const activeSlide = carouselElement.querySelector('.carousel-item.active');
      const activeIndex = Array.from(carouselElement.querySelectorAll('.carousel-item')).indexOf(activeSlide);
  
      // Reset 'active' class for all dots
      carouselItems.forEach((dot) => dot.classList.remove('active'));
  
      // Add 'active' class to the correct dot
      if (carouselItems[activeIndex]) {
        carouselItems[activeIndex].classList.add('active');
      }
    };
  
    // Attach event listener to handle slide changes
    carouselElement.addEventListener('slide.bs.carousel', updateActiveDot);
  
    // Initial active dot update
    updateActiveDot();
  
    return () => {
      carouselElement.removeEventListener('slide.bs.carousel', updateActiveDot);
    };
  }, []);  

  return (
    <div className="container mb-3 pb-4">
      <div className="row">
        {/* Quote Section */}
        <div className="col-md-8 d-flex flex-column justify-content-between ">
          <div className="carousel" data-bs-ride="carousel">
            <div className="carousel-inner">
              {[1, 2, 3, 4].map((_, index) => (
                <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                  <div className="row">
                    <blockquote className="blockquote w-100">
                      <p className="reviewPara">
                        "Carrefour is crucial in marketing. The more campaigns we can put
                        together, the more pages we can create, the bigger we feel, and
                        the more touch points we have with customers. Mozayc unlocks that
                        for us and allows us to do more with less."
                      </p>
                    </blockquote>
                    <div className="d-flex align-items-center mt-3">
                      <img
                        src={Oval}
                        alt="Karen Yue"
                        className="rounded-circle me-3"
                        width="50"
                        height="50"
                      />
                      <div>
                        <h6 className="mb-0">Karen Yue</h6>
                        <small className="text-muted">Director of Marketing</small>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Dot Indicators */}
            <div className="carousel-indicators-costom d-flex py-4 justify-content-start">
              {[1, 2, 3, 4].map((_, index) => (
                <button
                  key={index}
                  type="button"
                  data-bs-target=".carousel"
                  data-bs-slide-to={index}
                  className={`indicator-dot ${index === 0 ? 'active' : ''}`}
                  aria-current={index === 0 ? 'true' : 'false'}
                  aria-label={`Slide ${index + 1}`}
                />
              ))}
            </div>
          </div>
        </div>

        {/* Stats Section */}
        <div className="statesdiv col-md-4 d-flex flex-column align-items-start justify-content-between bg-light p-4">
          <img src={Bitmap} alt="Carrefour Logo" className="mb-4" width="80" />

          <div className="stats py-3 row d-flex">
            <div className="col-6">
              <h4 className="stats-heading">80%</h4>
              <p className="stats-para">Faster load times</p>
            </div>
            <div className="col-6">
              <h4 className="stats-heading">20x</h4>
              <p className="stats-para">Faster page updates</p>
            </div>
            <div className="col-6">
              <h4 className="stats-heading">500+</h4>
              <p className="stats-para">Employees</p>
            </div>
          </div>

          <div className="stats-link-div py-3 col-12">
            <a href="#" className="stats-link text-decoration-none mt-4">
              <div className="d-flex align-items-center justify-content-between">
                <span>Try similar shop for you</span>
                <img src={ArrowUp} />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewSlider;
