import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Faqs = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const Navigate = useNavigate();

  const faqs = [
    {
      question: "What is Mozayc and how does it work?",
      answer: "Mozayc is a platform that...",
    },
    {
      question: "How much does Mozayc cost?",
      answer: "The cost of Mozayc depends on...",
    },
    {
      question: "How long are your contracts?",
      answer: "Our contracts are flexible and...",
    },
    {
      question: "Can I cancel my account at any time?",
      answer: "Yes, you can cancel your account...",
    },
    {
      question: "Can I change my plan later on?",
      answer: "You can upgrade or downgrade your plan...",
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <div className="container py-4">
        <div className="row">
          <div className="faqs-heading mb-5">
            <h2 className="faqs-head">Frequently asked questions</h2>
            <p className="businessPara faqs-para">
              Learn little bit deeply about Mozayc
            </p>
          </div>

          <div className="faq-section pb-5">
            {faqs.map((faq, index) => (
              <div key={index} className="faq-item">
                <div
                  className={`faq-question py-2 px-4 ${
                    activeIndex === index ? "active-faq" : ""
                  }`}
                  onClick={() => toggleFAQ(index)}
                >
                  <span
                    className={`plus-minus-faqs pe-5 ${
                      activeIndex === index ? "active-icon" : ""
                    }`}
                  >
                    {activeIndex === index ? "-" : "+"}
                  </span>
                  <div
                    className={`faq-question-text ${
                      activeIndex === index ? "active-text" : ""
                    }`}
                  >
                    <span>{faq.question}</span>
                    {activeIndex === index && (
                      <div className="faq-answer pt-2">
                        <p className="faq-answer-para">{faq.answer}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
            <div className="show-more">
              <button
                className="col-12"
                onClick={() => Navigate("/helpcenter/faqs")}
              >
                Show more questions
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faqs;
