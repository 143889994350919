import React from "react";
import HeaderSection from "./HeaderSection";
import "./LandingMain.css";
import IntroCards from "./IntroCards";
import BusinessSection from "./BusinessSection";
import MozaycTools from "./MozaycTools";
import CheckOutSection from "./CheckOutSection";
import ReviewSlider from "./ReviewSlider";
import BlogSection from "./BlogSection";

const LandingMain = () => {
  return (
    <>
      <HeaderSection />
      <IntroCards />
      <BusinessSection />
      <MozaycTools />
      <CheckOutSection />
      <ReviewSlider />
      <BlogSection />
    </>
  );
};

export default LandingMain;
