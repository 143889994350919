import React from "react";
import FilterImg from "../../assets/img/filter_icon.svg";
import FolderImg from "../../assets/img/folder_icon.svg";
import HomeImg from "../../assets/img/home_icon.svg";
import MailImg from "../../assets/img/mail_icon.svg";

const IntroCards = () => {
  return (
    <>
      <section className="introCards-section mt-0 pt-5 p-0">
        <div className="container">
          <div className="row">
            {/* Info Heading Div starts here */}
            <div className="infoCards-heading col-md-8 text-start">
              <p className="introCardsPara">
                — Professional tools for your eshop
              </p>
              <h1 className="introCardsHead text-start">
                Next dimension of online selling experience with infinity posibilities.
              </h1>
            </div>

            {/* Info Cards Div Starts Here */}
            <div className="infoCards-main mt-3 row">
              <div className="infoCard col-12 col-sm-6 col-md-4">
                <div className="infoCardBorder d-flex flex-column align-items-start py-4 pl-0 pe-sm-0 pe-md-5">
                  <img className="infoCardImg pb-4" src={FilterImg} />
                  <h5 className="infoCardHeading fw-700">
                    Content Management System
                  </h5>
                  <p className="infoCardPara text-start">
                    Full featured CMS with SEO and languages management will
                    take care that your clients will quickly find you on the
                    internet whatever language they speak.
                  </p>
                </div>
              </div>

              <div className="infoCard col-12 col-sm-6 col-md-4">
                <div className="infoCardBorder d-flex flex-column align-items-start py-4 pl-0 pe-sm-0 pe-md-5">
                  <img className="infoCardImg pb-4" src={FolderImg} />
                  <h5 className="infoCardHeading fw-700">Product management</h5>
                  <p className="infoCardPara text-start">
                    if you are selling one product or thousands of products,
                    Mozayc has all the right tools for your products lists,
                    catalouges, catagories, attributes, gallaries, codes and
                    much more.
                  </p>
                </div>
              </div>

              <div className="infoCard col-12 col-sm-6 col-md-4">
                <div className="infoCardBorder d-flex flex-column align-items-start py-4 pl-0 pe-sm-0 pe-md-5">
                  <img className="infoCardImg pb-4" src={FilterImg} />
                  <h5 className="infoCardHeading fw-700">
                    Content Management System
                  </h5>
                  <p className="infoCardPara text-start">
                    Full featured CMS with SEO and languages management will
                    take care that your clients will quickly find you on the
                    internet whatever language they speak.
                  </p>
                </div>
              </div>

              <div className="infoCard col-12 col-sm-6 col-md-4">
                <div className="infoCardBorder d-flex flex-column align-items-start py-4 pl-0 pe-sm-0 pe-md-5">
                  <img className="infoCardImg pb-4" src={HomeImg} />
                  <h5 className="infoCardHeading fw-700">Warehouse management</h5>
                  <p className="infoCardPara text-start">
                    Distribute your products along virtual or physical
                    warehouses to effectively adderes different markets or
                    client groups with different markets or client groups.
                  </p>
                </div>
              </div>

              <div className="infoCard col-12 col-sm-6 col-md-4">
                <div className="infoCardBorder d-flex flex-column align-items-start py-4 pl-0 pe-sm-0 pe-md-5">
                  <img className="infoCardImg pb-4" src={MailImg} />
                  <h5 className="infoCardHeading fw-700">Marketing tools</h5>
                  <p className="infoCardPara text-start">
                    Automation is the key for your growth. SMS, e-mailing,
                    newsletters, special offers planning, discount management,
                    detailed analytics, you have it all instantly.
                  </p>
                </div>
              </div>

              <div className="infoCard col-12 col-sm-6 col-md-4">
                <div className="infoCardBorder d-flex flex-column align-items-start py-4 pl-0 pe-sm-0 pe-md-5">
                  <img className="infoCardImg pb-4" src={HomeImg} />
                  <h5 className="infoCardHeading fw-700">Warehouse management</h5>
                  <p className="infoCardPara text-start">
                    Distribute your products along virtual or physical
                    warehouses to effectively adderes different markets or
                    client groups with different markets or client groups.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IntroCards;
