import React from "react";
import Vodafone from "../../../assets/img/vodafone.png";
import Respekt from "../../../assets/img/respekt.png";
import Maersk from "../../../assets/img/maersk.png";
import Robot from "../../../assets/img/robot.png";
import Pravo from "../../../assets/img/pravo.png";
import Garrefour from "../../../assets/img/garrefour.png";
import Allianz from "../../../assets/img/allianz.png";
import Cnc from "../../../assets/img/cnc.png";
import Prima from "../../../assets/img/Prima.png";
import E15 from "../../../assets/img/E15.png";
import Dpd from "../../../assets/img/Dpd.png";
import Ctk from "../../../assets/img/Ctk.png";
import Tesco from "../../../assets/img/Tesco.png";
import Mafra from "../../../assets/img/Mafra.png";
import Lviminia from "../../../assets/img/Lviminia.png";
import Siemens from "../../../assets/img/Siemens.png";

const References = () => {
  return (
    <>
      <div className="container pb-5">
        <div className="row bg-references-head">
          <div className="faqs-heading mb-3">
            <h2 className="faqs-head">Let’s join 500+</h2>
            <p className="businessPara faqs-para">
              satisfied customers all over the world
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="row references-logos d-flex justify-content-between align-items-center p-4">
              <div className="col-lg-1 col-6 col-sm-3 py-3 py-lg-0 references-logo-div">
                <img className="references-logo-img" src={Respekt} />
              </div>
              <div className="col-lg-1 col-6 col-sm-3 py-3 py-lg-0 references-logo-div">
                <img className="references-logo-img" src={Maersk} />
              </div>
              <div className="col-lg-1 col-6 col-sm-3 py-3 py-lg-0 references-logo-div">
                <img className="references-logo-img" src={Vodafone} />
              </div>
              <div className="col-lg-1 col-6 col-sm-3 py-3 py-lg-0 references-logo-div">
                <img className="references-logo-img" src={Robot} />
              </div>
              <div className="col-lg-1 col-6 col-sm-3 py-3 py-lg-0 references-logo-div">
                <img className="references-logo-img" src={Pravo} />
              </div>
              <div className="col-lg-1 col-6 col-sm-3 py-3 py-lg-0 references-logo-div">
                <img className="references-logo-img" src={Garrefour} />
              </div>
              <div className="col-lg-1 col-6 col-sm-3 py-3 py-lg-0 references-logo-div">
                <img className="references-logo-img" src={Allianz} />
              </div>
              <div className="col-lg-1 col-6 col-sm-3 py-3 py-lg-0 references-logo-div">
                <img className="references-logo-img" src={Cnc} />
              </div>
            </div>
          </div>

          <div className="col-12 pb-5">
            <div className="row references-logos d-flex justify-content-between align-items-center px-4 py-3">
              <div className="col-lg-1 col-6 col-sm-3 py-3 py-lg-0 references-logo-div">
                <img className="references-logo-img" src={Prima} />
              </div>
              <div className="col-lg-1 col-6 col-sm-3 py-3 py-lg-0 references-logo-div">
                <img className="references-logo-img" src={E15} />
              </div>
              <div className="col-lg-1 col-6 col-sm-3 py-3 py-lg-0 references-logo-div">
                <img className="references-logo-img" src={Dpd} />
              </div>
              <div className="col-lg-1 col-6 col-sm-3 py-3 py-lg-0 references-logo-div">
                <img className="references-logo-img" src={Ctk} />
              </div>
              <div className="col-lg-1 col-6 col-sm-3 py-3 py-lg-0 references-logo-div">
                <img className="references-logo-img" src={Tesco} />
              </div>
              <div className="col-lg-1 col-6 col-sm-3 py-3 py-lg-0 references-logo-div">
                <img className="references-logo-img" src={Mafra} />
              </div>
              <div className="col-lg-1 col-6 col-sm-3 py-3 py-lg-0 references-logo-div">
                <img className="references-logo-img" src={Lviminia} />
              </div>
              <div className="col-lg-1 col-6 col-sm-3 py-3 py-lg-0 references-logo-div">
                <img className="references-logo-img" src={Siemens} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default References;
