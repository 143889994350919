import React from "react";
import Apparel from "../../assets/img/Materasso_alternative1.png";
import Lusaka from "../../assets/img/Materasso_home1.png";
import { Link } from "react-router-dom";

const TemplatesShowcase = () => {
  return (
    <>
      <section className="mt-5 mb-4 mt-md-0 pt-0">
        <div className="container">
          {/* Theme showcase Heading */}
          <div className="row justify-content-center mt-5">
            <div className="businessHeadingDiv col-12 col-md-8 d-flex flex-column align-items-center">
              <h1 className="businessHeading headerHeading">
                Make any template
                <br />
                yours with ease.
              </h1>
              <p className="businessPara headingPara Templates-showcase-para col-9 p-3">
                You can make any template do what you want. It only takes
                seconds to style and add features.
              </p>
            </div>
            <div className=" row template-catogary-btn-main d-flex justify-content-center">
              <div className="col-6 col-md-4 col-lg-2 px-1">
                <button className="w-100 template-catogary-btn-active col-4 col-md-4 col-lg-2 px-5 py-2 mx-lg-1 my-1">
                  All
                </button>
              </div>
              <div className="col-6 col-md-4 col-lg-2 px-1">
                <button className="w-100 template-catogary-btn col-4 col-md-4 col-lg-2 px-5 py-2 mx-lg-1 my-1">
                  Exclusive
                </button>
              </div>
              <div className="col-6 col-md-4 col-lg-2 px-1">
                <button className="w-100 template-catogary-btn col-4 col-md-4 col-lg-2 px-5 py-2 mx-lg-1 my-1">
                  Modern
                </button>
              </div>
              <div className="col-6 col-md-4 col-lg-2 px-1">
                <button className="w-100 template-catogary-btn col-4 col-md-4 col-lg-2 px-5 py-2 mx-lg-1 my-1">
                  Classic
                </button>
              </div>
              <div className="col-6 col-md-4 col-lg-2 px-1">
                <button className="w-100 template-catogary-btn col-4 col-md-4 col-lg-2 px-5 py-2 mx-lg-1 my-1">
                  Clean
                </button>
              </div>
            </div>
          </div>

          {/* Themes  */}
          <div className="container mt-5 pt-3">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="theme-main p-2">
                  <div
                    class="card px-5 pb-0 pt-5 position-relative"
                    style={{
                      background: "#50332C",
                      border: "none",
                      borderRadius: "0",
                    }}
                  >
                    <div
                      class="card py-0"
                      style={{ border: "none", borderRadius: "0" }}
                    >
                      <img
                        style={{ border: "none", borderRadius: "0" }}
                        class="card-img-top"
                        src={Apparel}
                        alt="Card image cap"
                      />
                    </div>
                    <div className="theme-btn-main w-100">
                      <div className="col-11">
                      <button className="btn btn-light theme-btn-show-more py-3 col-12 col-lg-6">
                        show more
                      </button>
                        <Link to="/showcases-details">
                      <button className="btn btn-primary theme-btn-get-theme py-3 col-12 col-lg-6">
                        Get this theme — it's free
                      </button>
                        </Link>
                        </div>
                    </div>
                  </div>
                  <div
                    class="card-body theme-name-main py-3"
                    style={{ background: "#fff" }}
                  >
                    <p class="card-text theme-name">
                      Apparel <span className="theme-sapn">theme</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="theme-main p-2">
                  <div
                    class="card px-5 pb-0 pt-5 position-relative"
                    style={{
                      background: "#1E293E",
                      border: "none",
                      borderRadius: "0",
                    }}
                  >
                    <div
                      class="card py-0"
                      style={{ border: "none", borderRadius: "0" }}
                    >
                      <img
                        style={{ border: "none", borderRadius: "0" }}
                        class="card-img-top"
                        src={Lusaka}
                        alt="Card image cap"
                      />
                    </div>
                    <div className="theme-btn-main col-11">
                      {/* <button className="theme-btn-show-more py-3 col-6">
                        show more
                      </button>
                      <button className="theme-btn-get-theme py-3 col-6">
                        Get this theme — it's free
                      </button> */}
                    </div>
                  </div>
                  <div
                    class="card-body theme-name-main py-3"
                    style={{ background: "#fff" }}
                  >
                    <p class="card-text theme-name">
                      Lusaka <span className="theme-sapn">theme</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="theme-main p-2">
                  <div
                    class="card px-5 pb-0 pt-5 position-relative"
                    style={{
                      background: "#50412C",
                      border: "none",
                      borderRadius: "0",
                    }}
                  >
                    <div
                      class="card py-0"
                      style={{
                        border: "none",
                        borderRadius: "0",
                        background: "#50412C",
                      }}
                    >
                      <img
                        style={{
                          border: "none",
                          borderRadius: "0",
                          zIndex: "-1",
                        }}
                        class="card-img-top"
                        src={Lusaka}
                        alt="Card image cap"
                      />
                    </div>
                    <div className="theme-btn-main col-11">
                      {/* <button className="theme-btn-show-more py-3 col-6">
                        show more
                      </button>
                      <button className="theme-btn-get-theme py-3 col-6">
                        Get this theme — it's free
                      </button> */}
                    </div>
                  </div>
                  <div
                    class="card-body theme-name-main py-3"
                    style={{ background: "#fff" }}
                  >
                    <p class="card-text theme-name">
                      Lusaka <span className="theme-sapn">theme</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="theme-main p-2">
                  <div
                    class="card px-5 pb-0 pt-5 position-relative"
                    style={{
                      background: "#3C1E3E",
                      border: "none",
                      borderRadius: "0",
                    }}
                  >
                    <div
                      class="card py-0"
                      style={{
                        border: "none",
                        borderRadius: "0",
                        background: "#3C1E3E",
                      }}
                    >
                      <img
                        style={{
                          border: "none",
                          borderRadius: "0",
                          zIndex: "-1",
                        }}
                        class="card-img-top"
                        src={Lusaka}
                        alt="Card image cap"
                      />
                    </div>
                    <div className="theme-btn-main col-11">
                      {/* <button className="theme-btn-show-more py-3 col-6">
                        show more
                      </button>
                      <button className="theme-btn-get-theme py-3 col-6">
                        Get this theme — it's free
                      </button> */}
                    </div>
                  </div>
                  <div
                    class="card-body theme-name-main py-3"
                    style={{ background: "#fff" }}
                  >
                    <p class="card-text theme-name">
                      Apparel <span className="theme-sapn">theme</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="theme-main p-2">
                  <div
                    class="card px-5 pb-0 pt-5 position-relative"
                    style={{
                      background: "#3C502C",
                      border: "none",
                      borderRadius: "0",
                    }}
                  >
                    <div
                      class="card py-0"
                      style={{
                        border: "none",
                        borderRadius: "0",
                        background: "#3C502C",
                      }}
                    >
                      <img
                        style={{
                          border: "none",
                          borderRadius: "0",
                          zIndex: "-1",
                        }}
                        class="card-img-top"
                        src={Lusaka}
                        alt="Card image cap"
                      />
                    </div>
                    <div className="theme-btn-main col-11">
                      {/* <button className="theme-btn-show-more py-3 col-6">
                        show more
                      </button>
                      <button className="theme-btn-get-theme py-3 col-6">
                        Get this theme — it's free
                      </button> */}
                    </div>
                  </div>
                  <div
                    class="card-body theme-name-main py-3"
                    style={{ background: "#fff" }}
                  >
                    <p class="card-text theme-name">
                      Apparel <span className="theme-sapn">theme</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="theme-main p-2">
                  <div
                    class="card px-5 pb-0 pt-5 position-relative"
                    style={{
                      background: "#1E313E",
                      border: "none",
                      borderRadius: "0",
                    }}
                  >
                    <div
                      class="card py-0"
                      style={{
                        border: "none",
                        borderRadius: "0",
                        background: "#1E313E",
                      }}
                    >
                      <img
                        style={{
                          border: "none",
                          borderRadius: "0",
                          zIndex: "-1",
                        }}
                        class="card-img-top"
                        src={Lusaka}
                        alt="Card image cap"
                      />
                    </div>
                    <div className="theme-btn-main col-11">
                      {/* <button className="theme-btn-show-more py-3 col-6">
                        show more
                      </button>
                      <button className="theme-btn-get-theme py-3 col-6">
                        Get this theme — it's free
                      </button> */}
                    </div>
                  </div>
                  <div
                    class="card-body theme-name-main py-3"
                    style={{ background: "#fff" }}
                  >
                    <p class="card-text theme-name">
                      Lusaka <span className="theme-sapn">theme</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="theme-main p-2">
                  <div
                    class="card px-5 pb-0 pt-5 position-relative"
                    style={{
                      background: "#50332C",
                      border: "none",
                      borderRadius: "0",
                    }}
                  >
                    <div
                      class="card py-0"
                      style={{
                        border: "none",
                        borderRadius: "0",
                        background: "#50332C",
                      }}
                    >
                      <img
                        style={{
                          border: "none",
                          borderRadius: "0",
                          zIndex: "-1",
                        }}
                        class="card-img-top"
                        src={Lusaka}
                        alt="Card image cap"
                      />
                    </div>
                    <div className="theme-btn-main col-11">
                      {/* <button className="theme-btn-show-more py-3 col-6">
                        show more
                      </button>
                      <button className="theme-btn-get-theme py-3 col-6">
                        Get this theme — it's free
                      </button> */}
                    </div>
                  </div>
                  <div
                    class="card-body theme-name-main py-3"
                    style={{ background: "#fff" }}
                  >
                    <p class="card-text theme-name">
                      Lusaka <span className="theme-sapn">theme</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="theme-main p-2">
                  <div
                    class="card px-5 pb-0 pt-5 position-relative"
                    style={{
                      background: "#1E293E",
                      border: "none",
                      borderRadius: "0",
                    }}
                  >
                    <div
                      class="card py-0"
                      style={{
                        border: "none",
                        borderRadius: "0",
                        background: "#1E293E",
                      }}
                    >
                      <img
                        style={{
                          border: "none",
                          borderRadius: "0",
                          zIndex: "-1",
                        }}
                        class="card-img-top"
                        src={Lusaka}
                        alt="Card image cap"
                      />
                    </div>
                    <div className="theme-btn-main col-11">
                      {/* <button className="theme-btn-show-more py-3 col-6">
                        show more
                      </button>
                      <button className="theme-btn-get-theme py-3 col-6">
                        Get this theme — it's free
                      </button> */}
                    </div>
                  </div>
                  <div
                    class="card-body theme-name-main py-3"
                    style={{ background: "#fff" }}
                  >
                    <p class="card-text theme-name">
                      Apparel <span className="theme-sapn">theme</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="theme-list-button-main pt-4">
                <button className="theme-list-button me-2 active">1</button>
                <button className="theme-list-button me-2">2</button>
                <button className="theme-list-button me-2">3</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TemplatesShowcase;
