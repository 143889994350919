import React, { useState } from "react";
import LogoImage from "../../assets/img/logo_symbol.svg";
import YearlyPrice from "../../assets/img/Combined Shape.svg";
// import Checkout from "../../assets/img/check.svg";
import { Monthly } from "./Monthly";
import { Yearly } from "./Yearly";

const CheckOutSection = () => {
  const [activePlan, setActivePlan] = useState("monthly"); // Default is "monthly"
  return (
    <>
      <section className="business-section mt-3 pt-0 p-0">
        <div className="container">
          {/* Business Header Image */}
          <div className="row">
            <div className="">
              <img className="businessHeadImage" src={LogoImage} />
            </div>
          </div>

          {/* Business Heading */}
          <div className="row justify-content-center mt-3">
            <div className="businessHeadingDiv col-12 col-md-8">
              <h1 className="businessHeading headerHeading">
                Check out
                <br />
                our early bird offer
              </h1>
              <p className="businessPara headingPara">
                Join our mission and support us with your super early bird
                purchase of a Mozayc package.
              </p>
            </div>
          </div>

          {/* <div className="row justify-content-center">
            <div className="pricingButtonsDiv col-10 col-md-8 col-lg-4">
              <button className="col-6 p-2 monthly-btn">Monthly</button>
              <button className="col-6 p-2 yearly-btn">
                <img className="me-2" src={YearlyPrice} />
                Yearly -20%
              </button>
            </div>
          </div> */}
          <div className="row justify-content-center pb-3">
            <div className="pricingButtonsDiv col-10 col-md-8 col-lg-4">
              <button
                className={`col-6 p-2 monthly-btn ${
                  activePlan === "monthly" ? "active-btn" : ""
                }`}
                onClick={() => setActivePlan("monthly")}
              >
                Monthly
              </button>
              <button
                className={`col-6 p-2 yearly-btn ${
                  activePlan === "yearly" ? "active-btn" : ""
                }`}
                onClick={() => setActivePlan("yearly")}
              >
                <img className="me-2" src={YearlyPrice} />
                Yearly -20%
              </button>
            </div>
          </div>

          {activePlan === "monthly" ? <Monthly /> : <Yearly />}
        </div>
      </section>
    </>
  );
};

export default CheckOutSection;
