import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./assets/vendor/main.css";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Header from "./component/Header";
import Footer from "./component/Footer";
import LandingMain from "./component/LandingPage/LandingMain";
import FeaturesMain from "./component/Features/FeaturesMain";
import HeaderFeatures from "./component/HeaderFeatures";
import { useEffect, useState } from "react";
import ScrollToTop from "../src/component/ScrollToTop";
import PricingMain from "./component/Pricing/PricingMain";
import PricingSecond from "./component/Pricing/PricingSecond/PricingSecond";
import ShowcaseMain from "./component/Shocase/ShocaseMain";
import ShowcaseDetails from "./component/Shocase/ShowcaseDetails";
import HelpCenterMain from "./component/Helpcenter/HelpCenterMain";
import Updates from "./component/Helpcenter/helpCenterPages/Updates";
import Faqs from "./component/Helpcenter/helpCenterPages/Faqs";
import Courses from "./component/Helpcenter/helpCenterPages/Courses";
import Login from "./component/User/Login";
import GetStarted from "./component/User/GetStarted";
import StepOne from "./component/User/StepOne";

function App() {
  const [isFeaturesPage, setIsFeaturesPage] = useState(false);
  const [isShowcaseDetailsPage, setIsShowcaseDetailsPage] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Check if the current path is "/features" and update the state
    setIsFeaturesPage(location.pathname === "/features");
    setIsShowcaseDetailsPage(location.pathname === "/showcases-details");
  }, [location]);

  return (
    <div className="App">
      <body className="index-page">
        <ScrollToTop />
        {/* Display HeaderFeatures only on the Features page, otherwise show Header */}
        {!isShowcaseDetailsPage &&
          (isFeaturesPage ? <HeaderFeatures /> : <Header />)}
        <Routes>
          <Route path="/" element={<LandingMain />} />
          <Route path="/features" element={<FeaturesMain />} />
          <Route path="/pricing" element={<PricingMain />} />
          <Route path="/pricing-second" element={<PricingSecond />} />
          <Route path="/showcases" element={<ShowcaseMain />} />
          <Route path="/showcases-details" element={<ShowcaseDetails />} />
          <Route path="/helpcenter" element={<HelpCenterMain />} />
          <Route path="/helpcenter/updates" element={<Updates />} />
          <Route path="/helpcenter/faqs" element={<Faqs />} />
          <Route path="/helpcenter/courses" element={<Courses />} />
          <Route path="/login" element={<Login />} />
          <Route path="/get-started" element={<GetStarted />} />
          <Route path="/get-started/step1" element={<StepOne />} />
        </Routes>
        <Footer />
      </body>
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
