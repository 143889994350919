import React from "react";
import { Link } from "react-router-dom";
import Arrow from "../../assets/img/arrow_right.svg";
import ArrowDown from "../../assets/img/arrow_main.svg";

const MozaycTools = () => {
  const buttonLabels = [
    "Design editor",
    "Site structure",
    "Warehouses",
    "Product logistics",
    "Product categories",
    "Advanced filter",
    "Product statuses",
    "Custom catalogues",
    "Product cards",
    "Customer relationship management ",
    "Professional pricing options",
    "Advanced filter",
    "Payout management ",
    "Product galeries",
    "Invoices",
    "Warehouses",
    "Product logistics",
    "Social networks",
    "Loyalty program",
    "More Features",
  ];

  return (
    <>
      <section className="mozaycTools-section">
        <div className="container-bottom-border mx-3">
          <div className="container my-3">
            {/* mozaycTools Heading Div starts here */}
            <div className="row">
              <div className="mt-5 infoCards-heading mozaycTools-heading col-md-10 text-start">
                <p className="introCardsPara mozaycTools-para">
                  — Enterprise level features
                </p>
                <h1 className="introCardsHead mozaycToolsHead">
                  <span className="me-2">Discover a seamless online business experience with the</span>
                  <span className="mozaycTools-colorfull-span">
                     powerful Mozayc tools.
                  </span>
                </h1>
              </div>
            </div>

            {/* mozaycTools buttons */}
            <div className="row mt-5 mb-4 gap-3">
              {buttonLabels.map((label, index) => (
                <div
                  key={index}
                  className="col-4 col-md-2 mozaycToolsButtons"
                  style={{ minWidth: "fit-content" }}
                >
                  <Link to="/features">
                    <span className="mozaycToolsButtons-span">
                      {label}
                      {label === "More Features" && (
                        <img className="px-2" src={Arrow} />
                      )}
                    </span>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row my-3">
            <div className="mt-3 infoCards-heading mozaycTools-heading col-md-10 text-start">
              <p className="introCardsPara mozaycTools-para">
                — Learn more about Mozayc
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="p-4 mozaycTools-info">
                <img
                  className="d-flex justify-content-start mb-3 mt-2 p-3"
                  src={ArrowDown}
                />
                <div className="p-3 mozaycTools-info-content d-flex flex-column justify-content-end">
                  <h1 className="mozaycTools-info-heading">All powerful</h1>
                  <h1 className="mozaycTools-info-heading">Mozayc features</h1>
                  <p className="mozaycTools-info-Para">
                    Get all the marketing and business strategy tips you need to
                    help you run an online business.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="p-4 mozaycTools-info">
                <img
                  className="d-flex justify-content-start mb-3 mt-2 p-3"
                  src={ArrowDown}
                />
                <div className="p-3 d-flex flex-column justify-content-end">
                  <h1 className="mozaycTools-info-heading">Online Courses</h1>
                  <p className="mozaycTools-info-Para">
                    Learn tried-and-tested business tips with instant access to
                    lessons from successful entrepreneurs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="p-4 mozaycTools-info">
                <img
                  className="d-flex justify-content-start mb-3 mt-2 p-3"
                  src={ArrowDown}
                />
                <div className="p-3 d-flex flex-column justify-content-end">
                  <h1 className="mozaycTools-info-heading">Help Center</h1>
                  <p className="mozaycTools-info-Para">
                    Find answers with a dedicated helpdesk resource full of
                    articles and videos from our Support team.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MozaycTools;
