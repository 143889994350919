import React from "react";
import ArrowDownBlack from "../../assets/img/arrow_down_black.svg";
import { Link } from "react-router-dom";

const HelpCenterCards = () => {
  return (
    <>
      <section className="mt-5 mt-md-0 pt-0" style={{ padding: "30px 0" }}>
        <div className="container">
          {/* Theme showcase Heading */}
          <div className="row justify-content-center mt-5">
            <div className="businessHeadingDiv col-12 col-md-8 d-flex flex-column align-items-center">
              <h1 className="headingHead-helpcenter">Mozayc Help Center</h1>
              <p className="headingPara-helpcenter p-3">
                Learn how to use Mozayc and find answers to common questions
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="p-4 mozaycTools-info Helpcenter-cards-bg">
                <Link to="#">
                  <img
                    className="d-flex justify-content-start mb-3 mt-4 p-3"
                    src={ArrowDownBlack}
                  />
                  <div className="p-3 mozaycTools-info-content d-flex flex-column justify-content-end">
                    <h1 className="mozaycTools-info-heading Helpcenter-cards-headings-bg">
                      Learn
                    </h1>
                    {/* <h1 className="mozaycTools-info-heading Helpcenter-cards-headings-bg">Mozayc features</h1> */}
                    <p className="mozaycTools-info-Para Helpcenter-cards-para-bg">
                      Get the most out of Mozayc with free self-guided courses
                      and live instructor-led training sessions.
                    </p>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="p-4 mozaycTools-info Helpcenter-cards-bg">
                <Link to="/helpcenter/courses">
                  <img
                    className="d-flex justify-content-start mb-3 mt-4 p-3"
                    src={ArrowDownBlack}
                  />
                  <div className="p-3 mozaycTools-info-content d-flex flex-column justify-content-end">
                    <h1 className="mozaycTools-info-heading Helpcenter-cards-headings-bg">
                      Online Courses
                    </h1>
                    {/* <h1 className="mozaycTools-info-heading Helpcenter-cards-headings-bg">Mozayc features</h1> */}
                    <p className="mozaycTools-info-Para Helpcenter-cards-para-bg">
                      Learn about your support options, file a support ticket,
                      and check the status of open tickets.
                    </p>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="p-4 mozaycTools-info Helpcenter-cards-bg">
                <Link to="/helpcenter/faqs">
                  <img
                    className="d-flex justify-content-start mb-3 mt-4 p-3"
                    src={ArrowDownBlack}
                  />
                  <div className="p-3 mozaycTools-info-content d-flex flex-column justify-content-end">
                    <h1 className="mozaycTools-info-heading Helpcenter-cards-headings-bg">
                      FAQ
                    </h1>
                    {/* <h1 className="mozaycTools-info-heading Helpcenter-cards-headings-bg">Mozayc features</h1> */}
                    <p className="mozaycTools-info-Para Helpcenter-cards-para-bg mb-md-0 mb-1">
                      Find answers with a dedicated helpdesk resource full of
                      articles and videos from our Support team.
                    </p>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="p-4 mozaycTools-info Helpcenter-cards-bg">
                <Link to="/helpcenter/updates">
                  <img
                    className="d-flex justify-content-start mb-3 mt-4 p-3"
                    src={ArrowDownBlack}
                  />
                  <div className="p-3 mozaycTools-info-content d-flex flex-column justify-content-end">
                    <h1 className="mozaycTools-info-heading Helpcenter-cards-headings-bg">
                      Updates & Releases
                    </h1>
                    {/* <h1 className="mozaycTools-info-heading Helpcenter-cards-headings-bg">Mozayc features</h1> */}
                    <p className="mozaycTools-info-Para Helpcenter-cards-para-bg">
                      Get the most out of Mozayc with free self-guided courses
                      and live instructor-led training sessions.
                    </p>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="p-4 mozaycTools-info Helpcenter-cards-bg">
                <Link to="#">
                  <img
                    className="d-flex justify-content-start mb-3 mt-4 p-3"
                    src={ArrowDownBlack}
                  />
                  <div className="p-3 mozaycTools-info-content d-flex flex-column justify-content-end">
                    <h1 className="mozaycTools-info-heading Helpcenter-cards-headings-bg">
                      Support
                    </h1>
                    {/* <h1 className="mozaycTools-info-heading Helpcenter-cards-headings-bg">Mozayc features</h1> */}
                    <p className="mozaycTools-info-Para Helpcenter-cards-para-bg">
                      Get the most out of Dropbox with free self-guided courses
                      and live instructor-led training sessions.
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HelpCenterCards;
