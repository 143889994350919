import React from 'react'
import "./Features.css";
import FeaturesHeaderSection from './FeaturesHeaderSection';
import AllFeatures from './AllFeatures';
import CheckOutSection from '../LandingPage/CheckOutSection';
import BlogSection from '../LandingPage/BlogSection';

const FeaturesMain = () => {
  return (
    <>
    <FeaturesHeaderSection />
    <AllFeatures />
    <CheckOutSection />
    <BlogSection />
    </>
  )
}

export default FeaturesMain;