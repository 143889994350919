import React from "react";
import "./PricingMain.css";
import OurEarlyBid from "./OurEarlyBid";
import ReviewSlider from "../LandingPage/ReviewSlider";
import Faqs from "./Faqs";
import BlogSection from '../LandingPage/BlogSection.js';

const PricingMain = () => {
  return (
    <>
      <OurEarlyBid />
      <ReviewSlider/>
      <Faqs />
      <BlogSection/>
    </>
  );
};

export default PricingMain;
