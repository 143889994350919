import React, { useState, useEffect } from "react";
import logo from "../assets/img/logo_mozayc.svg";
import Planet from "../assets/img/icon_planet.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./Header.css";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState("/");

  const location = useLocation(); // Hook to get the current location (URL)
  const Navigate = useNavigate();

  // Toggle the hamburger menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const loginClick = () => {
    Navigate("/login");
    setIsMenuOpen(false);
  };

  const logoutClick = () => {
    Navigate("/get-started");
    setIsMenuOpen(false);
  };

  // Handle active category change
  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    setIsMenuOpen(false);
  };

  // Update active category based on the current URL
  useEffect(() => {
    const path = location.pathname.split("/")[1]; // Get the part after "/"

    // Map the path back to category names
    const categoryMap = {
      features: "Features",
      pricing: "Pricing",
      showcases: "Showcases",
      helpcenter: "Help Center",
      //blog: "Blog",
    };

    // Set the active category based on the path
    setActiveCategory(categoryMap[path] || "/"); // Default to "/" if path is not found
  }, [location]);

  return (
    <header
      id="header"
      className="header py-3 header-custom d-flex align-items-center sticky-top"
    >
      <div className="container-fluid position-relative">
        <nav id="navmenu" className="navmenu text-center">
          <div className="row align-items-center justify-content-between">
            {/* Logo */}
            <div className="col-3">
              <Link to="/" className="logo d-flex align-items-center">
                <img src={logo} alt="Logo" aria-label="Logo" />
              </Link>
            </div>

            {/* Links and Buttons for Larger Screens */}
            <div className="col-5 d-none d-xl-flex justify-content-center">
              <ul className="header-links-list d-flex">
                {[
                  "Features",
                  "Pricing",
                  "Showcases",
                  "Help Center",
                  //"Blog",
                ].map((category) => (
                  <li
                    key={category}
                    className={`header-links ${
                      activeCategory === category ? "active-category" : ""
                    }`}
                  >
                    <Link
                      to={`/${category.replace(/\s+/g, "").toLowerCase()}`}
                      onClick={() => handleCategoryClick(category)}
                    >
                      {category}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-4 d-none d-xl-flex justify-content-end">
              {/* <img className="mx-3" src={Planet} alt="Planet Icon" />
              <button
                className="header-button-login mx-2 px-4 btn btn-light"
                onClick={() => loginClick()}
              >
                Login
              </button> */}
              <button
                className="header-button-start mx-2 btn btn-primary"
                onClick={() => logoutClick()}
              >
                Contact Us
              </button>
            </div>

            {/* Hamburger Icon for Small Screens */}
            <div className="col-3 d-xl-none d-flex">
              <button className="hamburger-btn" onClick={toggleMenu}>
                <span
                  className={`hamburger-icon ${isMenuOpen ? "open" : ""}`}
                ></span>
              </button>
            </div>
          </div>

          {/* Dropdown Menu for Small Screens */}
          {isMenuOpen && (
            <div className="dropdown-menu-small d-xl-none">
              <ul className="header-links-list dropdown flex-column">
                {[
                  "Features",
                  "Pricing",
                  "Showcases",
                  "Help Center",
                  //"Blog",
                ].map((category) => (
                  <li
                    key={category}
                    className={`dropdown-links ${
                      activeCategory === category ? "active-category" : ""
                    }`}
                  >
                    <Link
                      to={`${category.toLowerCase().replace(/\s+/g, "")}`}
                      onClick={() => handleCategoryClick(category)}
                    >
                      {category}
                    </Link>
                  </li>
                ))}
              </ul>
              <div className="col-8 col-sm-4 d-xl-none d-flex flex-column">
                {/* <button
                  className="header-button-login m-2 px-4 btn btn-light"
                  onClick={() => loginClick()}
                >
                  Login
                </button> */}
                <button
                  className="header-button-start my-1 mx-2 btn btn-primary"
                  onClick={() => logoutClick()}
                >
                  Contact Us
                </button>
              </div>
            </div>
          )}
        </nav>
      </div>
    </header>
  );
};

export default Header;
